/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { FiPlus, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { SyncLoader } from 'react-spinners';
import defaultPic from '../../assets/insert-picture-icon.png';

import {
  Container,
  Title,
  Close,
  InputSection,
  PhotoInput,
  Image,
} from './styles';

import ModalLarge from '../ModalLarge';
import Input from '../Input';
import Button from '../Button';
import { uploadPhoto } from '../../utils/uploadPhoto';

interface AdminProps {
  id: string;
  name: string;
}

interface NewsProps {
  id: string;
  photo: string;
  title: string;
  description: string;
  admin: AdminProps;
  admin_id: string;
  adminName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddNews: (
    newNews: Omit<
      NewsProps,
      | 'id'
      | 'adminName'
      | 'dateFormatted'
      | 'admin'
      | 'created_at'
      | 'updated_at'
    >,
  ) => void;
}

const ModalAddNews: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddNews,
}) => {
  const [filePicture, setFilePicture] = useState<any | null>(defaultPic);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: NewsProps) => {
      const newData = data;
      newData.photo = filePicture;
      handleAddNews(newData);
      setIsOpen();
    },
    [filePicture, handleAddNews, setIsOpen],
  );

  const handlePhoto = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLoading(true);
      uploadPhoto(e.target.files[0]).then(response => {
        setLoading(false);
        setFilePicture(response);
      });
    }
  }, []);

  return (
    <ModalLarge isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Adicionar</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputSection>
              <Input placeholder="Título" name="title" />
              <Input
                placeholder="URL da noticia"
                name="description"
                type="url"
              />
              <PhotoInput>
                {loading ? (
                  <div className="loading-img">
                    <SyncLoader color="#0F4780" loading />
                  </div>
                ) : (
                  <Image src={filePicture} alt="imagem" />
                )}
                <label htmlFor="photo">
                  <FiPlus />
                  <input type="file" id="photo" onChange={handlePhoto} />
                </label>
              </PhotoInput>
            </InputSection>
            <Button type="submit" buttonStyle="primary">
              Adicionar
            </Button>
          </Form>
        </section>
      </Container>
    </ModalLarge>
  );
};

export default ModalAddNews;
