import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiSearch } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import getValidationErrors from '../../utils/getValidationError';
import { Container, HeaderSection, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface AdminProps {
  id: string;
  name: string;
}

interface SearchProps {
  search: string;
}

interface NotificationsProps {
  id: string;
  photo: string;
  title: string;
  description: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}
const Notification: React.FC = () => {
  const { admin } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRefNotification = useRef<FormHandles>(null);
  const [allNotifications, setAllNotifications] = useState<
    NotificationsProps[]
  >([]);
  const [notifications, setNotifications] = useState<NotificationsProps[]>([]);

  const notificationsHeader = [
    {
      title: 'Título',
      value: 'title',
    },
    {
      title: 'Conteúdo',
      value: 'content',
    },
    {
      title: 'Data',
      value: 'dateFormatted',
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadNotifications(): Promise<void> {
      const response = await api.get('/notification/all');

      const NotificationsFormatted = response.data.map(
        (notificationsResponse: NotificationsProps) => {
          return {
            ...notificationsResponse,
            dateFormatted: format(
              new Date(notificationsResponse.created_at),
              'dd/MM/yyyy',
            ),
          };
        },
      );

      setAllNotifications(NotificationsFormatted);
      setNotifications(NotificationsFormatted);
    }
    loadNotifications();
  }, []);

  const handleSubmit = useCallback(
    async (data: SearchProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          search: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const fuse = new Fuse(allNotifications, {
          keys: [
            {
              name: 'title',
              weight: 2,
            },
            {
              name: 'content',
              weight: 1,
            },
          ],
        });
        if (data.search === '') {
          setNotifications(allNotifications);
        } else {
          const res = fuse.search(data.search).map(result => {
            return result.item;
          });
          setNotifications(res);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [allNotifications],
  );

  async function handleAddNotification(
    newNotification: Omit<
      NotificationsProps,
      | 'id'
      | 'adminName'
      | 'dateFormatted'
      | 'admin'
      | 'created_at'
      | 'updated_at'
    >,
  ): Promise<void> {
    try {
      const notificationsFormatted = newNotification;

      const response = await api.post<NotificationsProps>(
        '/notification/all',
        notificationsFormatted,
      );

      const newNotificationsFormatted = response.data;

      newNotificationsFormatted.dateFormatted = format(
        new Date(newNotificationsFormatted.created_at),
        'dd/MM/yyyy',
      );

      setNotifications([newNotificationsFormatted, ...notifications]);
      setAllNotifications([newNotificationsFormatted, ...notifications]);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Container>
      <Menu />
      <Body title="Notificação">
        <Content>
          <HeaderSection>
            <Form ref={formRefNotification} onSubmit={handleAddNotification}>
              <Input placeholder="Titulo" name="title" />
              <Input placeholder="Conteudo" name="content" />
              <Button buttonStyle="success" type="submit">
                Adicionar News
              </Button>
            </Form>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                placeholder="Pesquisar Título"
                name="search"
                icon={FiSearch}
              />
              <Button buttonStyle="primary" type="submit">
                Pesquisar
              </Button>
            </Form>
          </HeaderSection>
          <Table
            data={notifications}
            header={notificationsHeader}
            pagination
            maxItemsPerPage={20}
          />
        </Content>
      </Body>
    </Container>
  );
};

export default Notification;
