/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useRef } from 'react';

import { FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  Container,
  Title,
  Close,
  FormContainer,
  SelectWithLabel,
  FormContainerSelect,
} from './styles';

import Modal from '../Modal';
import Input from '../Input';
import Button from '../Button';
import Table, { HeaderProps } from '../Table';
import Select from '../Select';

interface PartiesProps {
  id: string;
  name: string;
  initial: string;
}

interface AutorProps {
  id: string;
  name: string;
  type:
    | 'Bancada Parlamentar'
    | 'Bloco Parlamentar'
    | 'Cidadão'
    | 'Comissão'
    | 'Conselhos'
    | 'Empresa'
    | 'Entidades'
    | 'Frente Parlamentar'
    | 'Órgão'
    | 'Parlamentar'
    | 'Partido'
    | 'Poder Executivo'
    | 'Poder Legislativo';
  party_id?: string;
  party: PartiesProps;
}

interface OptionSelect {
  label: string;
  value: string | number | boolean;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddAutors: (newAutor: Omit<AutorProps, 'id'>) => void;
  handleDeleteAutors: (id: string) => void;
  partiesSelect: OptionSelect[];
  autors: AutorProps[];
}

const ModalAddAutor: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddAutors,
  handleDeleteAutors,
  partiesSelect,
  autors,
}) => {
  const formRef = useRef<FormHandles>(null);

  const headerAutors: HeaderProps[] = [
    {
      title: 'Nome',
      value: 'name',
    },
    {
      title: 'Tipo',
      value: 'type',
    },
  ];

  const handleSubmit = useCallback(
    async (data: AutorProps) => {
      const newData = {
        ...data,
        party_id: '',
      };
      handleAddAutors(newData);
    },
    [handleAddAutors],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Autor(a)</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <Input placeholder="Nome" name="name" />
              <Select
                placeholder="Título"
                name="type"
                options={[
                  {
                    value: 'Bancada Parlamentar',
                    label: 'Bancada Parlamentar',
                  },
                  { value: 'Bloco Parlamentar', label: 'Bloco Parlamentar' },
                  { value: 'Cidadão', label: 'Cidadão' },
                  { value: 'Comissão', label: 'Comissão' },
                  { value: 'Conselhos', label: 'Conselhos' },
                  { value: 'Empresa', label: 'Empresa' },
                  { value: 'Entidades', label: 'Entidades' },
                  { value: 'Frente Parlamentar', label: 'Frente Parlamentar' },
                  { value: 'Órgão', label: 'Órgão' },
                  { value: 'Parlamentar', label: 'Parlamentar' },
                  { value: 'Partido', label: 'Partido' },
                  { value: 'Poder Executivo', label: 'Poder Executivo' },
                  { value: 'Poder Legislativo', label: 'Poder Legislativo' },
                ]}
              />
            </FormContainer>
            <Button type="submit" buttonStyle="success">
              Adicionar
            </Button>
          </Form>
          <Table data={autors} header={headerAutors} />
        </section>
      </Container>
    </Modal>
  );
};

export default ModalAddAutor;
