export const odsStore: odsImage = {
  '1': '1 - Erradicação da probreza',
  '2': '2 - Fome zero e agricultura sustentável',
  '3': '3 - Saúde e Bem-estar',
  '4': '4 - Educação de qualidade',
  '5': '5 - igualdade de gênero',
  '6': '6 - Água potável e saneamento',
  '7': '7 - Energia limpa e acessível',
  '8': '8 - Trabalho decente e crescimento econômico',
  '9': '9 - Indústria, inovação e infraestrutura',
  '10': '10 - Redução das desigualdades',
  '11': '11 - Cidades e comunidades sustentáveis',
  '12': '12 - Consumo e produção responsáveis',
  '13': '13 - Ação contra a mudança global do clima',
  '14': '14 - Vida na água',
  '15': '15 - Vida terrestre',
  '16': '16 - Paz, justiça e instituições',
  '17': '17 - Parcerias e meios de implementação',
};

type odsImage = {
  [key: string]: string;
};
