import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  justify-content: center;
  color: var(--color-text);
  font-size: 1.75rem;
  header {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  section {
    margin: 1rem 0 0.5rem 0;
  }
  a {
    text-decoration: none;
    color: var(--color-text);
  }
  @media (min-width: 1100px) {
    width: 768px;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: stretch;
  align-items: center;
  flex: 1;
  column-gap: 1rem;
`;
export const SecondSection = styled.div`
  display: grid;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 0.5rem 0;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 0.5rem;

  > div {
    & + div {
      margin-top: 0;
    }
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 3.5rem;
  img {
    margin-right: 0.5rem;
  }
`;
export const Close = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 0.75rem 0;
  svg {
    min-width: 1.75rem;
    margin-right: 0.5rem;
  }
`;

export const AvatarInput = styled.div`
  position: relative;
  align-self: center;

  img {
    width: 11rem;
    height: 11rem;
    border-radius: 1rem;
  }

  label {
    position: absolute;
    width: 4rem;
    height: 4rem;
    background: var(--color-background-selected-menubar);
    border-radius: 1rem;
    right: 0rem;
    bottom: 0.25rem;
    border: 0;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    input {
      display: none;
    }

    svg {
      width: 2rem;
      height: 2rem;
      color: #fff;
    }

    &:hover {
      background: var(--color-background-menubar);
    }
  }
`;
