import { usePDF } from '@react-pdf/renderer';
import React, {
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  useEffect,
} from 'react';

import { Container } from './styles';

type ButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  document: any;
  fileName: string;
};

const ButtonPDF: React.FC<ButtonProps> = ({
  document: Document,
  children,
  fileName,
  ...rest
}) => {
  const [instance, updateInstance] = usePDF({ document: Document });

  useEffect(() => {
    updateInstance();
  }, [Document, updateInstance]);

  return (
    <Container href={instance.url || ''} download={fileName} {...rest}>
      {children}
    </Container>
  );
};

export default ButtonPDF;
