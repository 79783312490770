/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback } from 'react';

import { FiX } from 'react-icons/fi';

import { Container, Title, Close } from './styles';

import Modal from '../Modal';
import Button from '../Button';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleDelete: (id: string) => void;
  title: string;
  text?: string;
  deleteNewsId: string;
}

const ModalDelete: React.FC<IModalProps> = ({
  title,
  text,
  isOpen,
  setIsOpen,
  handleDelete,
  deleteNewsId,
}) => {
  const handleSubmit = useCallback(
    async (id: string) => {
      handleDelete(id);
      setIsOpen();
    },
    [handleDelete, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>{title}</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          {text && <p>{text}</p>}
          <Button
            type="button"
            buttonStyle="danger"
            onClick={() => handleSubmit(deleteNewsId)}
          >
            Deletar
          </Button>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalDelete;
