import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  padding: 0.75rem 0;
  max-height: 6rem;
  margin-bottom: 2rem;

  form {
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: row;
    column-gap: 1rem;
    margin-right: 1rem;

    > button {
      margin-top: 0;
      max-width: 20%;
    }
  }
`;
