/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useRef, useState } from 'react';

import { FiDownload, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { format } from 'date-fns';
import { Container, Title, Close } from './styles';

import Modal from '../Modal';
import Input from '../Input';
import ButtonPDF from '../ButtonPDF';
import { ResumaoPDF } from '../../pages/Subjects/subjectsPdf';
import { SubjectProps } from '../../pages/Subjects';

interface FilterProps {
  year: number;
  startData: Date;
  endData: Date;
  autor: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  subjects: SubjectProps[];
}

const ModalResumaoSubject: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  subjects,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [resumoTitle, setResumoTitle] = useState('');

  console.log(resumoTitle);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Resumão</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={() => {}}>
            <Input
              placeholder="Titulo do resumão"
              name="resumo"
              value={resumoTitle}
              onChange={e => setResumoTitle(e.target.value)}
            />
            <ButtonPDF
              document={<ResumaoPDF title={resumoTitle} data={subjects} />}
              fileName={`${resumoTitle.split(' ').join('_')}.pdf`}
            >
              <FiDownload />
              Baixar Resumão
            </ButtonPDF>
          </Form>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalResumaoSubject;
