import React from 'react';

import { Container, BodySection, BodyHeader } from './styles';

interface BodyProps {
  title: string;
  subTitle?: string;
}

const Body: React.FC<BodyProps> = ({ title, subTitle, children }) => {
  return (
    <Container>
      <BodyHeader>
        <h1>{title}</h1>
        {subTitle && <h2>{subTitle}</h2>}
      </BodyHeader>

      <BodySection>{children}</BodySection>
    </Container>
  );
};

export default Body;
