import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: var(--color-text);
  font-size: 1.75rem;
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  section {
    margin: 1rem 0 0.5rem 0;
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  a {
    text-decoration: none;
    color: var(--color-text);
  }
  form {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    width: 1100px;
  }
`;

export const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  flex: 1;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 3.5rem;
  img {
    margin-right: 0.5rem;
  }
`;
export const Close = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 0.75rem 0;
  svg {
    min-width: 1.75rem;
    margin-right: 0.5rem;
  }
`;

export const PhotoInput = styled.div`
  position: relative;
  align-self: center;
  width: 15rem;
  height: 15rem;
  border: 1px dashed var(--color-background-selected-menubar);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > .loading-img {
    width: 15rem;
    max-height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label {
    position: absolute;
    width: 4rem;
    height: 4rem;
    background: var(--color-background-selected-menubar);
    border-radius: 1rem;
    right: 1rem;
    bottom: 0.5rem;
    border: 0;
    transition: background-color 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    input {
      display: none;
    }

    svg {
      width: 2rem;
      height: 2rem;
      color: #fff;
    }

    &:hover {
      background: var(--color-background-menubar);
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1.5rem;

  > div + div {
    margin-top: 0;
  }

  > button {
    margin: 0;
    height: 100%;
  }
`;

export const Image = styled.img`
  width: 14.5rem;
  max-height: 14.5rem;
`;
