/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable prettier/prettier */
/* eslint-disable no-extra-boolean-cast */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  FiDownload,
  FiFile,
  FiFilter,
  FiRefreshCw,
  FiSearch,
  FiSettings,
} from 'react-icons/fi';
import { Form } from '@unform/web';
import { format, formatISO, isAfter, isBefore } from 'date-fns';
import { FormHandles } from '@unform/core';
import Fuse from 'fuse.js';
import * as Yup from 'yup';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Menu from '../../components/Menu';
import Body from '../../components/Body';

import { Container, HeaderSection, Content, AddContainer } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import ModalAddSubject from '../../components/ModalAddSubject';
import { useAuth } from '../../hooks/auth';
import ModalDelete from '../../components/ModalDelete';
import ModalEditSubject from '../../components/ModalEditSubject';
import ModalAddType from '../../components/ModalAddType';
import ModalAddAutor from '../../components/ModalAddAutor';
import getValidationError from '../../utils/getValidationError';
import ModalFilter from '../../components/ModalFilterSubject';
import { ResumaoPDF } from './subjectsPdf';
import ButtonPDF from '../../components/ButtonPDF';
import ModalResumaoSubject from '../../components/ModalResumaoSubject';

interface SearchProps {
  search: string;
}
interface PartiesProps {
  id: string;
  name: string;
  initial: string;
}

interface AutorProps {
  id: string;
  name: string;
  type:
    | 'Bancada Parlamentar'
    | 'Bloco Parlamentar'
    | 'Cidadão'
    | 'Comissão'
    | 'Conselhos'
    | 'Empresa'
    | 'Entidades'
    | 'Frente Parlamentar'
    | 'Órgão'
    | 'Parlamentar'
    | 'Partido'
    | 'Poder Executivo'
    | 'Poder Legislativo';
  party_id?: string;
  party: PartiesProps;
}

interface TypesProps {
  id: string;
  title: string;
  code: string;
  description: string;
}

interface FilterProps {
  year?: number;
  startDate?: string;
  endDate?: string;
  autor?: string;
}
export interface SubjectProps {
  id: string;
  type_id: string;
  type: TypesProps;
  typeCode: string;
  year: number;
  number: number;
  numberFormatted: string;
  summary: string;
  initialDate: Date;
  initialDateFormatted: string;
  autor_id: string;
  autor: AutorProps;
  autorName: string;
  status: string;
  url: string;
  howIVoted: 'sim' | 'nao' | 'abs';
  create_by_admin_id: string;
  created_at: Date;
  updated_at: Date;
  ods: string;
  odsFormatted: string[];
}

interface OptionSelect {
  label: string;
  value: string | number | boolean;
}

const Subjects: React.FC = () => {
  const { admin } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRefResumo = useRef<FormHandles>(null);
  const [subjects, setSubjects] = useState<SubjectProps[]>([]);
  const [allSubjects, setAllSubjects] = useState<SubjectProps[]>([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editSubject, setEditSubject] = useState<SubjectProps>(
    {} as SubjectProps,
  );
  const [deleteSubject, setDeleteSubject] = useState<string>('');

  const [types, setTypes] = useState<TypesProps[]>([]);
  const [typesSelect, setTypesSelect] = useState<OptionSelect[]>([]);
  const [addModalType, setAddModalType] = useState(false);

  const [autors, setAutors] = useState<AutorProps[]>([]);
  const [autorsSelect, setAutorsSelect] = useState<OptionSelect[]>([]);
  const [addModalAutor, setAddModalAutor] = useState(false);

  const [filters, setFilters] = useState<FilterProps>({
    autor: undefined,
    year: undefined,
    startDate: formatISO(new Date('2020-01-01')),
    endDate: formatISO(new Date()),
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [modalResumo, setModalResumo] = useState(false);
  const [subjectsToResumo, setSubjectsToResumo] = useState<SubjectProps[]>([])

  const subjectHeader = [
    {
      title: 'Tipo',
      value: 'typeCode',
      sortable: true,
    },
    {
      title: 'Resumo',
      value: 'summary',
    },
    {
      title: 'Autor(a)',
      value: 'autorName',
      sortable: true,
    },
    {
      title: 'Numero',
      value: 'numberFormatted',
      sortable: true,
    },
    {
      title: 'Data de Ínicio',
      value: 'initialDateFormatted',
      sortable: true,
    },
  ] as HeaderProps[];

  // Subject
  useEffect(() => {
    async function loadSubject(): Promise<void> {
      const searchAutor = !!filters.autor ? `autor=${filters.autor}&` : '';
      const searchYear = !!filters.year ? `year=${filters.year}&` : '';
      const searchStartDate = !!filters.startDate
        ? `startDate=${formatISO(new Date(filters.startDate))}&`
        : `startDate=${formatISO(new Date('2020-01-01'))}&`;
      const searchEndDate = !!filters.endDate
        ? `endDate=${formatISO(new Date(filters.endDate))}`
        : `endDate=${formatISO(new Date('2020-01-01'))}`;

      const response = await api.get(
        `/subject?${
          searchAutor + searchYear + searchStartDate + searchEndDate
        }`,
      );

      const messageFormatted = response.data.map(
        (messageResponse: SubjectProps) => {
          return {
            ...messageResponse,
            typeCode: messageResponse.type.code,
            numberFormatted: `${messageResponse.number}/${messageResponse.year}`,
            autorName: messageResponse.autor.name,
            initialDateFormatted: format(
              new Date(messageResponse.initialDate),
              'dd/MM/yyyy',
            ),
            odsFormatted: messageResponse?.ods.split(',')
          };
        },
      );

      setSubjects(messageFormatted);
    }
    loadSubject();
  }, [filters]);
  // All Subject
  useEffect(() => {
    async function loadSubject(): Promise<void> {

      const response = await api.get(
        `/subject`,
      );

      const messageFormatted = response.data.map(
        (messageResponse: SubjectProps) => {
          return {
            ...messageResponse,
            typeCode: messageResponse.type.code,
            numberFormatted: `${messageResponse.number}/${messageResponse.year}`,
            autorName: messageResponse.autor.name,
            initialDateFormatted: format(
              new Date(messageResponse.initialDate),
              'dd/MM/yyyy',
            ),
            odsFormatted: messageResponse?.ods.split(',')
          };
        },
      );

      setAllSubjects(messageFormatted);
    }
    loadSubject();
  }, []);
  // types
  useEffect(() => {
    async function loadTypes(): Promise<void> {
      const response = await api.get('/subject/types');
      console.log(response);

      const selectTypes = [] as OptionSelect[];

      response.data.forEach((type: TypesProps) => {
        selectTypes.push({ label: type.title, value: type.id });
      });

      setTypesSelect(selectTypes);
      setTypes(response.data);
    }
    loadTypes();
  }, []);
  // autors
  useEffect(() => {
    async function loadAutors(): Promise<void> {
      const response = await api.get('/subject/autors');
      console.log(response);

      const selectAutors = [] as OptionSelect[];

      response.data.forEach((type: AutorProps) => {
        selectAutors.push({ label: type.name, value: type.id });
      });

      setAutorsSelect(selectAutors);
      setAutors(response.data);
    }
    loadAutors();
  }, []);

  function toggleAddModal(): void {
    setAddModal(!addModal);
  }

  function toggleModalFilter(): void {
    setModalFilter(!modalFilter);
  }

  function toggleModalResumo(): void {
    setModalResumo(!modalResumo);
  }

  function toggleEditModal(): void {
    setEditModal(!editModal);
  }

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  async function handleDeleteSubject(id: string): Promise<void> {
    await api.delete(`/subject/${id}`);
    const updateSubject = subjects.filter(deleteSub => deleteSub.id !== id);
    setAllSubjects([...updateSubject]);
    setSubjects([...updateSubject]);
  }

  function handleEditSubject(updateSubject: SubjectProps): void {
    setEditSubject(updateSubject);
    toggleEditModal();
  }

  function handleDeleteSubjectModal(id: string): void {
    setDeleteSubject(id);
    toggleDeleteModal();
  }

  async function handleAddSubject(
    subject: Omit<
      SubjectProps,
      | 'id'
      | 'numberFormatted'
      | 'initialDateFormatted'
      | 'autorName'
      | 'autor'
      | 'typeCode'
      | 'type'
      | 'created_at'
      | 'updated_at'
    >,
  ): Promise<void> {
    try {
      const newSubject = subject;

      newSubject.create_by_admin_id = admin.id;

      const response = await api.post('/subject', newSubject);
      const subjectFormatted = response.data;

      const type = await api.get(`/subject/types/find/${newSubject.type_id}`);
      subjectFormatted.type = type.data;

      const autor = await api.get(
        `/subject/autors/find/${newSubject.autor_id}`,
      );
      subjectFormatted.autor = autor.data;

      subjectFormatted.typeCode = subjectFormatted.type.code;
      subjectFormatted.numberFormatted = `${subjectFormatted.number}/${subjectFormatted.year}`;
      subjectFormatted.autorName = subjectFormatted.autor.name;
      subjectFormatted.initialDateFormatted = format(
        new Date(subjectFormatted.initialDate),
        'dd/MM/yyyy',
      );

      setAllSubjects([subjectFormatted, ...subjects]);
      setSubjects([subjectFormatted, ...subjects]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateSubject(
    updatedSubject: Omit<
      SubjectProps,
      | 'id'
      | 'numberFormatted'
      | 'initialDateFormatted'
      | 'autorName'
      | 'autor'
      | 'typeCode'
      | 'type'
      | 'created_at'
      | 'updated_at'
    >,
  ): Promise<void> {
    const subjectWithAdminId = updatedSubject;
    subjectWithAdminId.create_by_admin_id = admin.id;
    await api
      .put(`/subject/${editSubject.id}`, subjectWithAdminId)
      .then(response => {
        const newSubject = response.data;

        const indexSubject = subjects.findIndex(
          findSubject => findSubject.id === editSubject.id,
        );

        newSubject.typeCode = newSubject.type.code;
        newSubject.numberFormatted = `${newSubject.number}/${newSubject.year}`;
        newSubject.autorName = newSubject.autor.name;
        newSubject.initialDateFormatted = format(
          new Date(newSubject.initialDate),
          'dd/MM/yyyy',
        );
        newSubject.odsFormatted = newSubject.ods.split(',');

        subjects[indexSubject] = newSubject;

        setAllSubjects([...subjects]);
        setSubjects([...subjects]);
      });
  }

  function toggleAddModalType(): void {
    setAddModalType(!addModalType);
  }

  async function handleDeleteType(id: string): Promise<void> {
    await api.delete(`/subject/types/${id}`);
    const updateType = types.filter(deType => deType.id !== id);
    const updateTypeSelect = typesSelect.filter(
      delSelect => delSelect.value !== id,
    );
    setTypesSelect([...updateTypeSelect]);
    setTypes([...updateType]);
  }

  async function handleAddTypes(
    newType: Omit<TypesProps, 'id'>,
  ): Promise<void> {
    try {
      const response = await api.post('/subject/types', newType);

      setTypesSelect([
        ...typesSelect,
        { label: response.data.title, value: response.data.id },
      ]);
      setTypes([...types, response.data]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleEditTypes(
    newType: TypesProps,
  ): Promise<void> {
    try {
      const response = await api.put(`/subject/types/${newType.id}`, newType);

      const indexSelect = typesSelect.findIndex(type => type.value === response.data.id)
      const index = types.findIndex(type => type.id === response.data.id)

      const updatedTypesSelect = typesSelect.slice()
      const updatedTypes = types.slice()

      updatedTypesSelect[indexSelect] = { label: response.data.title, value: response.data.id }
      updatedTypes[index] = response.data

      setTypesSelect(updatedTypesSelect);
      setTypes(updatedTypes);
    } catch (err) {
      console.log(err);
    }
  }

  function toggleAddModalAutor(): void {
    setAddModalAutor(!addModalAutor);
  }

  async function handleDeleteAutor(id: string): Promise<void> {
    await api.delete(`/subject/autors/${id}`);
    const updateAutor = autors.filter(delAutor => delAutor.id !== id);

    const updateAutorSelect = autorsSelect.filter(
      delParties => delParties.value !== id,
    );
    setAutorsSelect([...updateAutorSelect]);
    setAutors([...updateAutor]);
  }

  async function handleAddAutors(
    newAutor: Omit<AutorProps, 'id'>,
  ): Promise<void> {
    try {
      const response = await api.post('/subject/autors', newAutor);

      setAutorsSelect([
        ...autorsSelect,
        { label: response.data.title, value: response.data.id },
      ]);
      setAutors([...autors, response.data]);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = useCallback(
    async (data: SearchProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          search: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const fuse = new Fuse(allSubjects, {
          keys: [
            {
              name: 'summary',
              weight: 2,
            },
          ],
        });
        if (data.search === '') {
          setSubjects(allSubjects);
        } else {
          const res = fuse.search(data.search).map(result => {
            return result.item;
          });
          setSubjects(res);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationError(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [allSubjects],
  );

  const handleFilter = useCallback((filter: FilterProps) => {
    setFilters(filter);
  }, []);

  const handleClearFilters = useCallback(() => {
    setFilters({
      autor: undefined,
      year: undefined,
      startDate: formatISO(new Date('2020-01-01')),
      endDate: formatISO(new Date()),
    });
  }, []);

  const handleSubjectsToResumo = useCallback((ids: string[]) => {

    const filteredSubjects = allSubjects.filter(sub => ids.includes(sub.id));
    const sortedSubjects = filteredSubjects.sort((a, b) => {
      if(isBefore(new Date(a.initialDate), new Date(b.initialDate))) return -1;
      if(isAfter(new Date(a.initialDate), new Date(b.initialDate))) return 1;
      return 0
    })
    setSubjectsToResumo(sortedSubjects)

  }, [allSubjects])

  return (
    <Container>
      <Menu />
      <Body title="News">
        <Content>
          <HeaderSection>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                placeholder="Pesquisar Título"
                name="search"
                icon={FiSearch}
              />
              <Button buttonStyle="primary" type="submit">
                Pesquisar
              </Button>
              <Button buttonStyle="primary" onClick={toggleModalFilter}>
                <FiFilter size={16} />
                Filtrar
              </Button>
              <Button buttonStyle="primary" onClick={handleClearFilters}>
                <FiRefreshCw size={16} />
                Limpar Filtros
              </Button>
            </Form>
            <AddContainer>
              <Button
                buttonStyle="success"
                type="button"
                onClick={toggleAddModal}
              >
                Adicionar Matéria
              </Button>
              <Button
                buttonStyle="primary"
                type="button"
                onClick={toggleAddModalType}
              >
                <FiSettings />
                Tipos de Matéria
              </Button>
              <Button
                buttonStyle="primary"
                type="button"
                onClick={toggleAddModalAutor}
              >
                <FiSettings />
                Autor (a)
              </Button>
              <Button
                buttonStyle="primary"
                type="button"
                onClick={toggleModalResumo}
              >
                <FiFile />
                Resumão
              </Button>
            </AddContainer>
          </HeaderSection>
          <Table
            data={subjects}
            header={subjectHeader}
            isEditable={handleEditSubject}
            isDelectable={handleDeleteSubjectModal}
            pagination
            isCheck={handleSubjectsToResumo}
          />
        </Content>
      </Body>
      <ModalAddSubject
        setIsOpen={toggleAddModal}
        isOpen={addModal}
        handleAddSubject={handleAddSubject}
        typeSelect={typesSelect}
        autorSelect={autorsSelect}
      />
      <ModalEditSubject
        setIsOpen={toggleEditModal}
        isOpen={editModal}
        handleEditSubject={handleUpdateSubject}
        editingSubject={editSubject}
        typeSelect={typesSelect}
        autorSelect={autorsSelect}
      />
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteSubject}
        deleteNewsId={deleteSubject}
      />
      <ModalAddType
        isOpen={addModalType}
        setIsOpen={toggleAddModalType}
        handleAddTypes={handleAddTypes}
        handleEditTypes={handleEditTypes}
        handleDeleteTypes={handleDeleteType}
        types={types}
      />
      <ModalAddAutor
        isOpen={addModalAutor}
        setIsOpen={toggleAddModalAutor}
        handleAddAutors={handleAddAutors}
        handleDeleteAutors={handleDeleteAutor}
        autors={autors}
        partiesSelect={[]}
      />
      <ModalFilter
        isOpen={modalFilter}
        setIsOpen={toggleModalFilter}
        handleFilter={handleFilter}
      />
      <ModalResumaoSubject
        isOpen={modalResumo}
        setIsOpen={toggleModalResumo}
        subjects={subjectsToResumo}
      />
    </Container>
  );
};

export default Subjects;
