import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-background);
  border-radius: 1rem;
  border: 2px solid var(--color-background-selected-menubar);
  padding: 1rem;
  height: 100%;
  width: 100%;
  color: var(--color-background-selected-menubar);
  display: flex;
  align-items: center;
  flex: 1;

  & + div {
    margin-top: 0.75rem;
  }

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--color-background-selected-menubar);

    &::placeholder {
      color: var(--color-placeholder);
    }
  }
`;
