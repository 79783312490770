/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import {
  Container,
  Content,
  BoxesContainers,
  GabineteSummary,
  LastNews,
  LastSubject,
  VereadorSummary,
  Title,
  LastNewsImage,
  LastNewsSummary,
  LastNewsSummaryContent,
  LastSubjectSummary,
} from './styles';
import api from '../../services/api';
import capitalize from '../../utils/capitalize';
import trimString from '../../utils/trimString';

interface GabineteProps {
  id: string;
  name: string;
  email: string;
  photo: string;
  responsibility: string;
  description: string;
  dateOfBirth: Date;
  date: string;
  gender: string;
  age: number;
  responsibilityFormatted: string;
  genderFormatted: 'Feminino' | 'Masculino' | 'Neutro';
}
interface AdminProps {
  id: string;
  name: string;
}
interface NewsProps {
  id: string;
  photo: string;
  title: string;
  description: string;
  admin: AdminProps;
  admin_id: string;
  adminName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}
interface PartiesProps {
  name: string;
  initial: string;
}
interface AutorProps {
  name: string;
  type:
    | 'Bancada Parlamentar'
    | 'Bloco Parlamentar'
    | 'Cidadão'
    | 'Comissão'
    | 'Conselhos'
    | 'Empresa'
    | 'Entidades'
    | 'Frente Parlamentar'
    | 'Órgão'
    | 'Parlamentar'
    | 'Partido'
    | 'Poder Executivo'
    | 'Poder Legislativo';
  party_id?: string;
  party: PartiesProps;
}
interface TypesProps {
  title: string;
  code: string;
}
interface SubjectProps {
  type_id: string;
  type: TypesProps;
  typeCode: string;
  year: number;
  number: number;
  numberFormatted: string;
  summary: string;
  initialDate: Date;
  initialDateFormatted: string;
  autor_id: string;
  autor: AutorProps;
  autorName: string;
  status: string;
  url: string;
  howIVoted: 'sim' | 'nao' | 'abs';
  create_by_admin_id: string;
  created_at: Date;
  updated_at: Date;
}

const Dashboard: React.FC = () => {
  const history = useHistory();

  const [gabinete, setGabinete] = useState<GabineteProps[]>([]);
  const [lastNews, setLastNews] = useState<NewsProps>();
  const [lastSubject, setLastSubject] = useState<SubjectProps>();

  useEffect(() => {
    async function loadGabinete(): Promise<void> {
      const response = await api.get('/gabinete');

      const gabineteFormatted = response.data.map((team: GabineteProps) => {
        return {
          ...team,
          responsibilityFormatted:
            team.gender === 'f'
              ? `${capitalize(team.responsibility)}a`
              : `${capitalize(team.responsibility)}`,
          genderFormatted: team.gender === 'f' ? 'Feminino' : 'Masculino',
        };
      });

      setGabinete(gabineteFormatted);
    }

    loadGabinete();
  }, []);

  useEffect(() => {
    async function loadNews(): Promise<void> {
      const response = await api.get('/news');

      const NewsFormatted = response.data.map((newsResponse: NewsProps) => {
        return {
          ...newsResponse,
          adminName: newsResponse.admin.name,
          dateFormatted: format(
            new Date(newsResponse.created_at),
            'dd/MM/yyyy',
          ),
        };
      }) as NewsProps[];

      setLastNews(NewsFormatted[0]);
    }
    loadNews();
  }, []);

  useEffect(() => {
    async function loadMessage(): Promise<void> {
      const response = await api.get('/subject');

      const messageFormatted = response.data.map(
        (messageResponse: SubjectProps) => {
          return {
            ...messageResponse,
            typeCode: messageResponse.type.code,
            numberFormatted: `${messageResponse.number}/${messageResponse.year}`,
            autorName: messageResponse.autor.name,
            initialDateFormatted: format(
              new Date(messageResponse.initialDate),
              'dd/MM/yyyy',
            ),
          };
        },
      ) as SubjectProps[];

      const lastIndex = messageFormatted.length - 1;

      setLastSubject(messageFormatted[lastIndex]);
    }
    loadMessage();
  }, []);

  const goTothePage = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  return (
    <Container>
      <Menu />
      <Body title="Dashboard">
        <Content>
          <BoxesContainers>
            <GabineteSummary onClick={() => goTothePage('gabinete')}>
              <Title>Gabinete</Title>
              {gabinete ? (
                gabinete.map(item => (
                  <VereadorSummary key={item.name}>
                    <img src={item.photo} alt={item.name} />
                    <div>
                      <h2>{item.name}</h2>
                      <p>{item.responsibilityFormatted}</p>
                    </div>
                  </VereadorSummary>
                ))
              ) : (
                <p>Não há nenhuma Vereadora ou Assessora cadastrada</p>
              )}
            </GabineteSummary>
            <LastSubject onClick={() => goTothePage('subject')}>
              <Title>Última matéria</Title>

              {lastSubject ? (
                <LastSubjectSummary>
                  <p>
                    <b>{`Resumo: ${lastSubject.summary}`}</b>
                  </p>
                  <p>{`Tipo: ${lastSubject.type.title}`}</p>
                  <p>{`Autor: ${lastSubject.autor.name} (${lastSubject.autor.type})`}</p>
                  <p>{`Número: ${lastSubject.number}/${lastSubject.year}`}</p>
                </LastSubjectSummary>
              ) : (
                <p>Nenhuma matéria</p>
              )}
            </LastSubject>
            <LastNews onClick={() => goTothePage('news')}>
              <Title>Última news</Title>
              {lastNews ? (
                <LastNewsSummary>
                  {lastNews?.photo && (
                    <LastNewsImage>
                      <img src={lastNews.photo} alt={lastNews.title} />
                    </LastNewsImage>
                  )}
                  <LastNewsSummaryContent>
                    <h3>{lastNews.title}</h3>
                    <div className="html-container" />
                  </LastNewsSummaryContent>
                </LastNewsSummary>
              ) : (
                <p>Nenhuma nóticia</p>
              )}
            </LastNews>
          </BoxesContainers>
        </Content>
      </Body>
    </Container>
  );
};

export default Dashboard;
