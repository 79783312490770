/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiFile, FiSearch } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format, isAfter, isBefore } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import { parseISO } from 'date-fns/esm';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import getValidationErrors from '../../utils/getValidationError';
import { Container, HeaderSection, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import ModalAddNews from '../../components/ModalAddNews';
import { useAuth } from '../../hooks/auth';
import ModalDelete from '../../components/ModalDelete';
import ModalAddJornal from '../../components/ModalAddJornal';
import ModalEditJornal from '../../components/ModalEditJornal';
import ModalJornalPDF from '../../components/ModalJornalPDF';

interface SearchProps {
  search: string;
}

export interface JornalProps {
  id: string;
  photo: string;
  title: string;
  description: string;
  date: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}
const Jornal: React.FC = () => {
  const { admin } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [allJornal, setAllJornal] = useState<JornalProps[]>([]);
  const [jornal, setJornal] = useState<JornalProps[]>([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editJornal, setEditJornal] = useState<JornalProps>({} as JornalProps);
  const [deleteJornal, setDeleteJornal] = useState<string>('');
  const [modalResumo, setModalResumo] = useState(false);
  const [jornalToResumo, setJornalToResumo] = useState<JornalProps[]>([]);

  const jornalHeader = [
    {
      title: 'Título',
      value: 'title',
    },
    {
      title: 'Data',
      value: 'dateFormatted',
      sortable: true,
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadJornal(): Promise<void> {
      const response = await api.get('/jornal');

      const jornalFormatted = response.data.map(
        (jornalResponse: JornalProps) => {
          return {
            ...jornalResponse,
            dateFormatted: format(
              new Date(parseISO(jornalResponse.date)),
              'dd/MM/yyyy',
            ),
            createdDateFormatted: format(
              new Date(jornalResponse.created_at),
              'dd/MM/yyyy',
            ),
          };
        },
      );

      setAllJornal(jornalFormatted);
      setJornal(jornalFormatted);
    }
    loadJornal();
  }, []);

  function toggleAddModal(): void {
    setAddModal(!addModal);
  }

  function toggleEditModal(): void {
    setEditModal(!editModal);
  }

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  const handleSubmit = useCallback(
    async (data: SearchProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          search: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const fuse = new Fuse(allJornal, {
          keys: [
            {
              name: 'title',
              weight: 2,
            },
            {
              name: 'description',
              weight: 1,
            },
          ],
        });
        if (data.search === '') {
          setJornal(allJornal);
        } else {
          const res = fuse.search(data.search).map(result => {
            return result.item;
          });
          setJornal(res);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [allJornal],
  );

  function handleEditJornal(updateNews: JornalProps): void {
    setEditJornal(updateNews);
    toggleEditModal();
  }

  async function handleAddNews(
    newJornal: Omit<
      JornalProps,
      | 'id'
      | 'adminName'
      | 'createdDateFormatted'
      | 'dateFormatted'
      | 'created_at'
      | 'updated_at'
    >,
  ): Promise<void> {
    try {
      const jornalFormatted = newJornal;

      const response = await api.post('/jornal', jornalFormatted);

      const newJornalFormatted = response.data;
      newJornalFormatted.dateFormatted = format(
        new Date(parseISO(newJornalFormatted.date)),
        'dd/MM/yyyy',
      );
      newJornalFormatted.createdDateFormatted = format(
        new Date(newJornalFormatted.created_at),
        'dd/MM/yyyy',
      );

      setJornal([newJornalFormatted, ...jornal]);
      setAllJornal([newJornalFormatted, ...jornal]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateNews(updatedJornal: JornalProps): Promise<void> {
    await api.put(`/jornal/${editJornal.id}`, updatedJornal).then(response => {
      const newJornal = response.data;

      const indexGabinete = jornal.findIndex(
        findJornal => findJornal.id === editJornal.id,
      );

      newJornal.dateFormatted = format(
        new Date(parseISO(newJornal.date)),
        'dd/MM/yyyy',
      );
      newJornal.createDateFormatted = format(
        new Date(newJornal.created_at),
        'dd/MM/yyyy',
      );

      jornal[indexGabinete] = newJornal;

      setJornal([...jornal]);
      setAllJornal([...jornal]);
    });
  }

  function handleDeleteJornalModal(id: string): void {
    setDeleteJornal(id);
    toggleDeleteModal();
  }

  async function handleDeleteJornal(id: string): Promise<void> {
    await api.delete(`/jornal/${id}`);
    const updateJornal = jornal.filter(item => item.id !== id);
    setJornal([...updateJornal]);
    setAllJornal([...updateJornal]);
  }

  function toggleModalResumo(): void {
    setModalResumo(!modalResumo);
  }

  const handleJornalToResumo = useCallback(
    (ids: string[]) => {
      const filteredJornal = allJornal.filter(sub => ids.includes(sub.id));
      const sortedJornal = filteredJornal.sort((a, b) => {
        if (isBefore(new Date(a.date), new Date(b.date))) return -1;
        if (isAfter(new Date(a.date), new Date(b.date))) return 1;
        return 0;
      });
      setJornalToResumo(sortedJornal);
    },
    [allJornal],
  );

  return (
    <Container>
      <Menu />
      <Body title="Jornal">
        <Content>
          <HeaderSection>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                placeholder="Pesquisar Título"
                name="search"
                icon={FiSearch}
              />
              <Button buttonStyle="primary" type="submit">
                Pesquisar
              </Button>
            </Form>
            <Button
              buttonStyle="success"
              type="button"
              onClick={toggleAddModal}
            >
              Adicionar Jornal
            </Button>
            <Button
              buttonStyle="primary"
              type="button"
              onClick={toggleModalResumo}
            >
              <FiFile />
              PDF
            </Button>
          </HeaderSection>
          <Table
            data={jornal}
            header={jornalHeader}
            pagination
            maxItemsPerPage={20}
            isEditable={handleEditJornal}
            isDelectable={handleDeleteJornalModal}
            isCheck={handleJornalToResumo}
          />
        </Content>
      </Body>
      <ModalAddJornal
        isOpen={addModal}
        setIsOpen={toggleAddModal}
        handleAddNews={handleAddNews}
      />
      <ModalEditJornal
        isOpen={editModal}
        setIsOpen={toggleEditModal}
        handleAddNews={handleUpdateNews}
        editJornal={editJornal}
      />
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteJornal}
        deleteNewsId={deleteJornal}
      />
      <ModalJornalPDF
        isOpen={modalResumo}
        setIsOpen={toggleModalResumo}
        jornal={jornalToResumo}
      />
    </Container>
  );
};

export default Jornal;
