import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0.5rem 0;
`;

export const RetractButton = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0.05rem solid var(--color-second-text);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    font-size: 1.25rem;
  }

  svg {
    margin-left: 0.5rem;
    font-size: 1.75rem;
    @media (min-width: 1100px) {
      font-size: 1.5rem;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: var(--color-background);
  border-radius: 0.5rem;
  border: 0.05rem solid var(--color-second-text);
`;
export const TypeContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  color: var(--color-second-text);
  padding: 0.5rem;
  border-right: 0.05rem solid var(--color-second-text);
  &:nth-last-child(1) {
    border-right: none;
  }
  svg {
    margin-right: 0.5rem;
    color: var(--color-second-text);
  }
  @media (max-width: 1100px) {
    p {
      display: none;
    }
  }
`;
