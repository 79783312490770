import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 700px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 2rem;
    }
  }

  a {
    color: var(--color-title-in-background);
    display: block;
    margin-top: 2rem;
    transition: color 0.2s;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--color-text-in-background);
    }
  }
`;
