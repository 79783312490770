/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useRef } from 'react';

import { FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  Container,
  Title,
  Close,
  FormContainer,
  SelectWithLabel,
} from './styles';

import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import MultiSelect from '../MultiSelect';
import Select from '../Select';
import DatePicker from '../DatePicker';

interface PartiesProps {
  name: string;
  initial: string;
}

interface AutorProps {
  name: string;
  type:
    | 'Bancada Parlamentar'
    | 'Bloco Parlamentar'
    | 'Cidadão'
    | 'Comissão'
    | 'Conselhos'
    | 'Empresa'
    | 'Entidades'
    | 'Frente Parlamentar'
    | 'Órgão'
    | 'Parlamentar'
    | 'Partido'
    | 'Poder Executivo'
    | 'Poder Legislativo';
  party_id?: string;
  party: PartiesProps;
}

interface TypesProps {
  title: string;
  code: string;
}

interface SubjectProps {
  type_id: string;
  type: TypesProps;
  typeCode: string;
  year: number;
  number: number;
  numberFormatted: string;
  summary: string;
  initialDate: Date;
  initialDateFormatted: string;
  autor_id: string;
  autor: AutorProps;
  autorName: string;
  status: string;
  url: string;
  howIVoted: 'sim' | 'nao' | 'abs';
  create_by_admin_id: string;
  ods: string;
  odsArray?: string[];
  created_at: Date;
  updated_at: Date;
  odsFormatted: string[];
}

interface OptionSelect {
  label: string;
  value: string | number | boolean;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddSubject: (
    newSubject: Omit<
      SubjectProps,
      | 'id'
      | 'adminName'
      | 'dateFormatted'
      | 'admin'
      | 'created_at'
      | 'updated_at'
    >,
  ) => void;
  typeSelect: OptionSelect[];
  autorSelect: OptionSelect[];
}

const ModalAddSubject: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddSubject,
  typeSelect,
  autorSelect,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SubjectProps) => {
      const newData: SubjectProps = {
        ...data,
        ods: data.odsArray?.join(',') || '',
        howIVoted: 'sim',
      };
      handleAddSubject(newData);
      setIsOpen();
    },
    [handleAddSubject, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Adicionar</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <Select
                name="type_id"
                placeholder="Tipo da matéria"
                options={typeSelect}
              />
              <DatePicker
                defaultDate={new Date()}
                placeholderText="Data Inicial"
                name="initialDate"
                dateFormat="dd/MM/yyyy"
              />
            </FormContainer>
            <FormContainer>
              <Input placeholder="Número" name="number" />
              <Input placeholder="Ano" name="year" />
            </FormContainer>
            <FormContainer>
              <Input placeholder="Situação" name="status" />
              <Select
                placeholder="Autor"
                name="autor_id"
                options={autorSelect}
              />
            </FormContainer>
            <MultiSelect
              placeholder="Selecione as ODS"
              name="odsArray"
              options={[
                { label: '1 - Erradicação da probreza', value: '1' },
                {
                  label: '2 - Fome zero e agricultura sustentável',
                  value: '2',
                },
                { label: '3 - Saúde e Bem-estar', value: '3' },
                { label: '4 - Educação de qualidade', value: '4' },
                { label: '5 - igualdade de gênero', value: '5' },
                { label: '6 - Água potável e saneamento', value: '6' },
                { label: '7 - Energia limpa e acessível', value: '7' },
                {
                  label: '8 - Trabalho decente e crescimento econômico',
                  value: '8',
                },
                {
                  label: '9 - Indústria, inovação e infraestrutura',
                  value: '9',
                },
                { label: '10 - Redução das desigualdades', value: '10' },
                {
                  label: '11 - Cidades e comunidades sustentáveis',
                  value: '11',
                },
                {
                  label: '12 - Consumo e produção responsáveis',
                  value: '12',
                },
                {
                  label: '13 - Ação contra a mudança global do clima',
                  value: '13',
                },
                { label: '14 - Vida na água', value: '14' },
                { label: '15 - Vida terrestre', value: '15' },
                { label: '16 - Paz, justiça e instituições', value: '16' },
                {
                  label: '17 - Parcerias e meios de implementação',
                  value: '17',
                },
              ]}
              isMulti
            />
            <FormContainer>
              <Input placeholder="Link da matéria" name="url" />
            </FormContainer>

            <TextArea placeholder="Resumo" name="summary" />

            <Button type="submit" buttonStyle="primary">
              Adicionar
            </Button>
          </Form>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalAddSubject;
