import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiSearch } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import Menu from '../../components/Menu';
import Body from '../../components/Body';

import getValidationErrors from '../../utils/getValidationError';
import { Container, HeaderSection, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import ModalDelete from '../../components/ModalDelete';

interface UserProps {
  id: string;
  name: string;
}

interface SearchProps {
  search: string;
}

interface MessageProps {
  id: string;
  title: string;
  message: string;
  read: boolean;
  readFormatted: string;
  user: UserProps;
  user_id: string;
  userName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}

const Message: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [allMessage, setAllMessage] = useState<MessageProps[]>([]);
  const [message, setMessage] = useState<MessageProps[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState<string>('');

  const messageHeader = [
    {
      title: 'Assunto',
      value: 'title',
    },
    {
      title: 'Data',
      value: 'dateFormatted',
    },
    {
      title: 'Lido',
      value: 'readFormatted',
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadMessage(): Promise<void> {
      const response = await api.get('/message');

      const messageFormatted = response.data.map(
        (messageResponse: MessageProps) => {
          return {
            ...messageResponse,
            userName: messageResponse.user.name,
            readFormatted: messageResponse.read ? 'Lido' : 'Não lido',
            dateFormatted: format(
              new Date(messageResponse.created_at),
              'dd/MM/yyyy',
            ),
          };
        },
      );

      setAllMessage(messageFormatted);
      setMessage(messageFormatted);
    }
    loadMessage();
  }, []);

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  function handleDeleteMessageModal(id: string): void {
    setDeleteMessage(id);
    toggleDeleteModal();
  }

  async function handleDeleteMessage(id: string): Promise<void> {
    await api.delete(`/message/${id}`);
    const updateMessage = message.filter(delMessage => delMessage.id !== id);
    setAllMessage([...updateMessage]);
    setMessage([...updateMessage]);
  }

  const handleSubmit = useCallback(
    async (data: SearchProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          search: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const fuse = new Fuse(allMessage, {
          keys: [
            {
              name: 'userName',
              weight: 2,
            },
          ],
        });
        if (data.search === '') {
          setMessage(allMessage);
        } else {
          const res = fuse.search(data.search).map(result => {
            return result.item;
          });
          setMessage(res);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [allMessage],
  );

  return (
    <Container>
      <Menu />
      <Body title="Mensagens">
        <Content>
          <HeaderSection>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                placeholder="Pesquisar Usuario"
                name="search"
                icon={FiSearch}
              />
              <Button buttonStyle="primary" type="submit">
                Pesquisar
              </Button>
            </Form>
          </HeaderSection>
          <Table
            data={message}
            header={messageHeader}
            isDelectable={handleDeleteMessageModal}
            isSelected
            path="answer/:id"
          />
        </Content>
      </Body>
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteMessage}
        deleteNewsId={deleteMessage}
      />
    </Container>
  );
};

export default Message;
