/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { FiCamera, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { formatISO } from 'date-fns';
import defaultUser from '../../assets/default-user-image.png';
import {
  Container,
  Title,
  Close,
  FirstSection,
  SecondSection,
  InputSection,
  AvatarInput,
} from './styles';

import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';
import DatePicker from '../DatePicker';
import Button from '../Button';

interface GabineteData {
  id: string;
  name: string;
  email: string;
  photo: string;
  responsibility: string;
  description: string;
  dateOfBirth: Date;
  date: string;
  gender: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddGabinete: (gabinete: Omit<GabineteData, 'id'>) => void;
  handleUpdateAvatarGabinete: (picture: any) => Promise<string>;
}

const ModalAddGabinete: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddGabinete,
  handleUpdateAvatarGabinete,
}) => {
  const [picture, setPicture] = useState<any | null>(defaultUser);
  const [filePicture, setFilePicture] = useState<any | null>(defaultUser);
  const formRef = useRef<FormHandles>(null);

  const responsabilitySelect = [
    { label: 'Vereadora', value: 'vereador' },
    { label: 'Assessora', value: 'assessor' },
  ];

  const genderSelect = [
    { label: 'Feminino', value: 'f' },
    { label: 'Masculino', value: 'm' },
    { label: 'Neutro', value: 'n' },
  ];

  const handleSubmit = useCallback(
    async (data: GabineteData) => {
      const newData = data;
      newData.photo = picture;
      newData.date = formatISO(newData.dateOfBirth);
      handleAddGabinete(newData);
      setIsOpen();
    },
    [handleAddGabinete, picture, setIsOpen],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];

        handleUpdateAvatarGabinete(file).then(response => {
          if (response) {
            setPicture(response);
          } else {
            setPicture('');
          }
        });
      }
    },
    [handleUpdateAvatarGabinete],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Adicionar</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FirstSection>
              <AvatarInput>
                <img src={picture || defaultUser} alt="foto" />
                <label htmlFor="photo">
                  <FiCamera />
                  <input type="file" id="photo" onChange={handleAvatarChange} />
                </label>
              </AvatarInput>
              <InputSection>
                <Input placeholder="Nome" name="name" />
                <Input placeholder="E-mail" name="email" />
              </InputSection>
            </FirstSection>
            <SecondSection>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                name="dateOfBirth"
                placeholderText="Data de Nascimento"
                defaultDate={new Date()}
                showYearDropdown
                showMonthDropdown
              />
              <Select
                options={responsabilitySelect}
                placeholder="Cargo"
                name="responsibility"
              />
              <Select
                options={genderSelect}
                placeholder="Gênero"
                name="gender"
              />
            </SecondSection>
            <TextArea placeholder="Descrição" name="description" />
            <Button type="submit" buttonStyle="primary">
              Adicionar
            </Button>
          </Form>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalAddGabinete;
