/* eslint-disable no-return-await */
import React, { useEffect, useState } from 'react';

import { FiPlus } from 'react-icons/fi';
import { differenceInYears, formatISO } from 'date-fns';
import Menu from '../../components/Menu';
import Body from '../../components/Body';

import {
  Container,
  GabineteCard,
  Title,
  Cargo,
  Age,
  Describe,
  Genero,
  GabineteContainer,
  BodyGabinenteCard,
  HeaderGabineteCard,
  FooterGabineteCard,
} from './styles';
import Button from '../../components/Button';
import api from '../../services/api';
import capitalize from '../../utils/capitalize';
import ModalEditGabinete from '../../components/ModalEditGabinete';
import ModalAddGabinete from '../../components/ModalAddGabinete';

interface GabineteProps {
  id: string;
  name: string;
  email: string;
  photo: string;
  responsibility: string;
  description: string;
  dateOfBirth: Date;
  date: string;
  gender: string;
  age: number;
  responsibilityFormatted: string;
  genderFormatted: 'Feminino' | 'Masculino' | 'Neutro';
}

interface GabineteData {
  id: string;
  name: string;
  email: string;
  photo: string;
  responsibility: string;
  description: string;
  dateOfBirth: Date;
  gender: 'm' | 'f' | 'n';
}

const Gabinete: React.FC = () => {
  const [gabinetes, setGabinetes] = useState<GabineteProps[]>([]);
  const [editingGabinete, setEditingGabinete] = useState<GabineteProps>(
    {} as GabineteProps,
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  function toggleEditModal(): void {
    setEditModalOpen(!editModalOpen);
  }

  function toggleAddModal(): void {
    setModalOpen(!modalOpen);
  }

  function handleEditGabinete(gabinete: GabineteProps): void {
    setEditingGabinete(gabinete);
    toggleEditModal();
  }

  async function handleDeleteGabinete(id: string): Promise<void> {
    await api.delete(`/gabinete/${id}`);
    const updateGabinete = gabinetes.filter(gabinete => gabinete.id !== id);
    setGabinetes(updateGabinete);
  }

  async function handleUpdateGabinete(
    gabinete: Omit<
      GabineteProps,
      'age' | 'responsibilityFormatted' | 'genderFormatted'
    >,
  ): Promise<void> {
    const updateGabinete = gabinete;
    updateGabinete.date = formatISO(updateGabinete.dateOfBirth);
    await api
      .put(`/gabinete/${updateGabinete.id}`, updateGabinete)
      .then(response => {
        let newGabinete = response.data;
        const updateGabinetes = gabinetes.slice();

        const indexGabinete = updateGabinetes.findIndex(
          findGabinete => findGabinete.id === updateGabinete.id,
        );

        newGabinete = {
          ...newGabinete,
          dateOfBirth: new Date(newGabinete.dateOfBirth),
          responsibilityFormatted:
            newGabinete.gender === 'f'
              ? `${capitalize(newGabinete.responsibility)}a`
              : `${capitalize(newGabinete.responsibility)}`,
          age: differenceInYears(new Date(), new Date(newGabinete.dateOfBirth)),
          genderFormatted:
            newGabinete.gender === 'f' ? 'Feminino' : 'Masculino',
        };

        updateGabinetes[indexGabinete] = newGabinete;

        setGabinetes(updateGabinetes);
      });
  }

  useEffect(() => {
    async function loadGabinete(): Promise<void> {
      const response = await api.get('/gabinete');

      const gabineteFormatted = response.data.map((team: GabineteProps) => {
        return {
          ...team,
          dateOfBirth: new Date(team.dateOfBirth),
          responsibilityFormatted:
            team.gender === 'f'
              ? `${capitalize(team.responsibility)}a`
              : `${capitalize(team.responsibility)}`,
          age: differenceInYears(new Date(), new Date(team.dateOfBirth)),
          genderFormatted: team.gender === 'f' ? 'Feminino' : 'Masculino',
        };
      });

      setGabinetes(gabineteFormatted);
    }

    loadGabinete();
  }, []);

  async function handleAddGabinete(
    gabinete: Omit<
      GabineteProps,
      'id' | 'age' | 'responsibilityFormatted' | 'genderFormatted'
    >,
  ): Promise<void> {
    try {
      const response = await api.post('/gabinete', gabinete);

      let newGabinete = response.data;

      newGabinete = {
        ...newGabinete,
        dateOfBirth: new Date(newGabinete.dateOfBirth),
        responsibilityFormatted:
          newGabinete.gender === 'f'
            ? `${capitalize(newGabinete.responsibility)}a`
            : `${capitalize(newGabinete.responsibility)}`,
        age: differenceInYears(new Date(), new Date(newGabinete.dateOfBirth)),
        genderFormatted: newGabinete.gender === 'f' ? 'Feminino' : 'Masculino',
      };

      setGabinetes([...gabinetes, newGabinete]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAvatarChange(picture: any): Promise<string> {
    const data = new FormData();

    data.append('avatar', picture);
    const response = await api.post(`/admins/file`, data);
    return response.data;
  }

  return (
    <>
      <ModalEditGabinete
        isOpen={editModalOpen}
        setIsOpen={toggleEditModal}
        handleUpdateGabinete={handleUpdateGabinete}
        editingGabinete={editingGabinete}
        handleUpdateAvatarGabinete={handleAvatarChange}
      />
      <ModalAddGabinete
        isOpen={modalOpen}
        setIsOpen={toggleAddModal}
        handleAddGabinete={handleAddGabinete}
        handleUpdateAvatarGabinete={handleAvatarChange}
      />
      <Container>
        <Menu />
        <Body title="Gabinete">
          <GabineteContainer>
            {gabinetes.map((item, index) => (
              <GabineteCard key={item.id}>
                <HeaderGabineteCard>
                  <img src={item.photo} alt={item.name} />
                  <Title>{item.name}</Title>
                  <Cargo>{item.responsibilityFormatted}</Cargo>
                </HeaderGabineteCard>
                <BodyGabinenteCard>
                  <Age>{`Idade: ${item.age} anos`}</Age>
                  <Genero>{`Gênero: ${item.genderFormatted}`}</Genero>
                  <Describe>{`Descrição: ${item.description}`}</Describe>
                </BodyGabinenteCard>
                <FooterGabineteCard>
                  <Button
                    onClick={() => handleEditGabinete(item)}
                    buttonStyle="primary"
                  >
                    Editar
                  </Button>
                  <Button
                    onClick={() => handleDeleteGabinete(item.id)}
                    buttonStyle="danger"
                  >
                    Deletar
                  </Button>
                </FooterGabineteCard>
              </GabineteCard>
            ))}

            <button onClick={toggleAddModal} type="button" className="add">
              <FiPlus />
            </button>
          </GabineteContainer>
        </Body>
      </Container>
    </>
  );
};

export default Gabinete;
