import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  buttonStyle: 'primary' | 'success' | 'danger';
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  buttonStyle,
  ...rest
}) => (
  <Container buttonStyle={buttonStyle} {...rest}>
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;
