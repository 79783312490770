import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  background: var(--color-background-menubar);
  flex-direction: column;
  width: 28rem;
  min-width: 26rem;
  align-items: flex-start;
  padding: 3rem 0.5rem 5rem 0.5rem;

  h1 {
    color: var(--color-text-in-menubar);
    font-size: 2.5rem;
    font-family: 'Roboto';
    font-weight: 500;
    margin: 3rem auto;
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: var(--color-text-in-menubar);
  font-size: 2.5rem;
  font-weight: 500;
  margin: 3rem auto;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  svg {
    margin-left: 1rem;
  }
`;

export const Tab = styled.div`
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition: 0.2s;
  width: 100%;

  a {
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 1.75rem;
    color: var(--color-text-in-menubar);
    font-weight: 400;
    display: flex;
    justify-items: center;

    svg {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 1rem;
    }
  }

  &:hover {
    background: var(--color-background-selected-menubar);
  }
`;

export const SubTab = styled.div`
  margin-top: 1rem;
  padding-top: 0.5rem 0 0.5rem 0;
  transition: 0.2s;
  width: 100%;

  & + div {
    margin-top: 0px;
  }

  a {
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 1.5rem;
    color: var(--color-text-in-menubar);
    font-weight: 400;
  }

  &:hover {
    background: var(--color-background-selected-menubar);
  }
`;
