import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  color: var(--color-text-in-background);
`;

export const BoxesContainers = styled.div`
  width: 100%;
  height: 100%;
  max-height: 80vh;
  flex: 1;
  display: grid;
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 50% 50%;
  grid-template-areas:
    'gabinete news'
    'gabinete subject';
`;
export const GabineteSummary = styled.div`
  grid-area: gabinete;
  background: #fff;
  border-radius: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 1.5rem;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
  overflow-y: auto;

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
`;
export const LastNews = styled.div`
  grid-area: news;
  background: #fff;
  border-radius: 1.5rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 1.5rem;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  transition: 0.2s;

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
`;
export const LastSubject = styled.div`
  grid-area: subject;
  background: #fff;
  border-radius: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 1.5rem;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  transition: 0.2s;

  &:hover {
    transform: scale(1.01);
    cursor: pointer;
  }
`;

export const VereadorSummary = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 0.75rem;

  > img {
    width: 7rem;
    height: 7rem;
    border-radius: 0.5rem;
  }
  > div {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    row-gap: 0.25rem;
    justify-content: start;
    align-items: flex-start;

    > h2 {
      font-size: 2.5rem;
      font-weight: 500;
      margin-block-start: 0;
      margin-left: 0;
    }
    > p {
      font-size: 1.75rem;
      margin: 0;
    }
  }
`;

export const Title = styled.div`
  font-size: 3rem;
  width: 100%;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: start;
`;

export const LastNewsSummary = styled.div`
  display: flex;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
`;

export const LastNewsImage = styled.div`
  display: flex;
  flex: 1;
  max-width: 20%;
  img {
    width: 100%;
    border-radius: 1rem;
  }
`;

export const LastNewsSummaryContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;

  > h2 {
    font-size: 2.5rem;
    margin-block-start: 0;
    margin-left: 0;
  }
  > .html-container {
    display: flex;
    width: 100%;
    max-height: 100%;
    flex-direction: column;
  }
`;

export const LastSubjectSummary = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;

  > p {
    font-size: 1.5rem;
    margin: 0.25rem 0;
  }
`;
