import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiSearch } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import Menu from '../../components/Menu';
import Body from '../../components/Body';

import getValidationErrors from '../../utils/getValidationError';
import { Container, HeaderSection, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import ModalDelete from '../../components/ModalDelete';

interface UserProps {
  id: string;
  name: string;
}

interface SearchProps {
  search: string;
}

interface DemandProps {
  id: string;
  title: string;
  message: string;
  read: boolean;
  readFormatted: string;
  user: UserProps;
  user_id: string;
  userName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}

const Demand: React.FC = () => {
  const [demands, setDemands] = useState<DemandProps[]>([]);

  const demandsHeader = [
    {
      title: 'Assunto',
      value: 'title',
    },
    {
      title: 'Data',
      value: 'dateFormatted',
    },
    {
      title: 'Lido',
      value: 'readFormatted',
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadMessage(): Promise<void> {
      const response = await api.get('/demand');

      const demandFormatted = response.data.map(
        (demandResponse: DemandProps) => {
          return {
            ...demandResponse,
            userName: demandResponse?.user?.name,
            dateFormatted: format(
              new Date(demandResponse.created_at),
              'dd/MM/yyyy',
            ),
          };
        },
      );
      setDemands(demandFormatted);
    }
    loadMessage();
  }, []);

  return (
    <Container>
      <Menu />
      <Body title="Demandas">
        <Content>
          <Table
            data={demands}
            header={demandsHeader}
            isSelected
            path="demand/:id"
          />
        </Content>
      </Body>
    </Container>
  );
};

export default Demand;
