import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 28rem);

  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
    margin-left: 2rem;
  }
`;

export const BodyHeader = styled.header`
  margin: 5rem 4rem 0rem 4rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-bottom: 0.25rem;
`;

export const BodySection = styled.section`
  padding: 2rem 4rem 2rem 4rem;
  display: flex;
`;
