import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';

interface SignInCredentials {
  email: string;
  password: string;
}

interface Admin {
  id: string;
  name: string;
  responsibility: string;
  email: string;
}

interface AuthContextState {
  admin: Admin;
  signIn(credations: SignInCredentials): Promise<void>;
  signOut(): void;
  updateAdmin(admin: Admin): void;
}

interface AuthState {
  token: string;
  admin: Admin;
  refreshToken: string;
}

export const AuthContext = createContext<AuthContextState>(
  {} as AuthContextState,
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);
  useEffect(() => {
    const token = localStorage.getItem('@AdminControl:token');
    const refreshToken = localStorage.getItem('@AdminControl:refreshToken');

    if (token) {
      api
        .get('admins')
        .then(response => {
          const admin = response.data;
          api.defaults.headers.authorization = `Bearer ${token}`;
          setData({
            admin,
            token,
            refreshToken: refreshToken || '',
          });
        })
        .catch(err => {
          localStorage.removeItem('@AdminControl:token');
          localStorage.removeItem('@AdminControl:admin');
          localStorage.removeItem('@AdminControl:refreshToken');

          setData({} as AuthState);
        });
    }
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessionsadm/admin', {
      email,
      password,
    });

    const { token, admin, refreshToken } = response.data;

    localStorage.setItem('@AdminControl:token', token);
    localStorage.setItem('@AdminControl:admin', JSON.stringify(admin));
    localStorage.setItem('@AdminControl:refreshToken', refreshToken);

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, admin, refreshToken });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@AdminControl:token');
    localStorage.removeItem('@AdminControl:admin');
    localStorage.removeItem('@AdminControl:refreshToken');

    setData({} as AuthState);
  }, []);

  const updateAdmin = useCallback(
    (admin: Admin) => {
      setData({
        token: data.token,
        admin,
        refreshToken: data.refreshToken,
      });

      localStorage.setItem('@AdminControl:admin', JSON.stringify(admin));
    },
    [setData, data.token, data.refreshToken],
  );
  return (
    <AuthContext.Provider
      value={{ admin: data.admin, signIn, signOut, updateAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextState {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
