import styled, { css } from 'styled-components';

interface ContainerProps {
  buttonStyle: 'primary' | 'success' | 'danger';
}
const buttonTypeVariations = {
  primary: css`
    background: var(--color-primary);
    color: var(--color-button-text);
    &:hover {
      background: var(--color-primary-shade);
    }
  `,
  success: css`
    background: var(--color-secondary);
    color: var(--color-button-text);
    &:hover {
      background: var(--color-secondary-shade);
    }
  `,
  danger: css`
    background: var(--color-danger);
    color: var(--color-button-text);
    &:hover {
      background: var(--color-danger-shade);
    }
  `,
};
export const Container = styled.button<ContainerProps>`
  min-height: 4rem;
  max-height: 6rem;
  border-radius: 0.75rem;
  border: 0;
  padding: 0 1rem;
  width: 100%;
  font-weight: 500;
  margin-top: 1rem;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: auto 0.5rem;
  }

  ${props => buttonTypeVariations[props.buttonStyle || 'primary']}
`;
