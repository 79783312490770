/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import { FiPlus, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { SyncLoader } from 'react-spinners';
import { format, formatISO } from 'date-fns';
import defaultPic from '../../assets/insert-picture-icon.png';

import {
  Container,
  Title,
  Close,
  InputSection,
  PhotoInput,
  Image,
  FormContainer,
} from './styles';

import ModalLarge from '../ModalLarge';
import Input from '../Input';
import Button from '../Button';
import { uploadPhoto } from '../../utils/uploadPhoto';
import TextArea from '../TextArea';
import DatePicker from '../DatePicker';

interface AdminProps {
  id: string;
  name: string;
}

interface CanaisProps {
  id: string;
  title: string;
  description: string;
  icon: string;
  url: string;
  priority: number;
  created_at: Date;
  updated_at: Date;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddCanal: (
    newNews: Omit<CanaisProps, 'id' | 'created_at' | 'updated_at'>,
  ) => void;
  priority: number;
}

const ModalAddCanal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddCanal,
  priority,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: CanaisProps) => {
      handleAddCanal({
        ...data,
        priority,
      });
      setIsOpen();
    },
    [handleAddCanal, priority, setIsOpen],
  );

  return (
    <ModalLarge isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Canais</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <InputSection>
              <Input placeholder="Título" name="title" />
              <Input placeholder="Descrição" name="description" />
              <div className="link">
                {'Copie o código do ícone no site '}
                <a
                  target="_blank"
                  href="https://oblador.github.io/react-native-vector-icons/"
                >
                  react-vector-icons
                </a>
                {' da seção Ionicons'}
              </div>
              <Input placeholder="Icone" name="icon" />
              <Input placeholder="Url" name="url" />
            </InputSection>
            <Button type="submit" buttonStyle="primary">
              Adicionar
            </Button>
          </Form>
        </section>
      </Container>
    </ModalLarge>
  );
};

export default ModalAddCanal;
