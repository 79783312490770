/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { FiPower } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import globalOptions from '../../config/global';
import { useAuth } from '../../hooks/auth';

import { Container, Tab, SubTab, Title } from './styles';

const Menu: React.FC = () => {
  const { admin, signOut } = useAuth();

  return (
    <Container>
      <Title>
        {admin.name}
        <div onClick={signOut}>
          <FiPower />
        </div>
      </Title>

      <hr />

      {admin.responsibility === 'admin'
        ? globalOptions.admin.map(tab => (
            <Tab>
              <Link to={tab.path}>
                <tab.icon />
                {tab.title}
              </Link>
              {tab.subMenu.map(subTab => (
                <SubTab>
                  <Link to={subTab.path}>{subTab.title}</Link>
                </SubTab>
              ))}
            </Tab>
          ))
        : globalOptions.editor.map(tab => (
            <Tab>
              <Link to={tab.path}>
                <tab.icon />
                {tab.title}
              </Link>
              {tab.subMenu.map(subTab => (
                <SubTab>
                  <Link to={subTab.path}>{subTab.title}</Link>
                </SubTab>
              ))}
            </Tab>
          ))}
    </Container>
  );
};

export default Menu;
