import styled, { css } from 'styled-components';

interface ContainerProps {
  isPreview: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 360px;
`;

export const IPhoneNewsPreview = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fefefb;
  padding-top: 16px;
`;

export const MainImage = styled.img`
  width: 100%;
  flex: 1;
  max-height: 300px;
`;
export const Title = styled.p`
  padding: 8px;
  font-size: 28px;
  color: #5e3612;
  font-family: 'Lato-Regular';
  line-height: 28px;
`;
export const ActionContainer = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 8px;
  }
`;
export const TextAction = styled.p`
  font-size: 18px;
  color: #5e3612;
  font-family: 'Lato-Regular';
`;

export const View = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
`;
