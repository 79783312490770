import styled from 'styled-components';

export const Container = styled.div`
  background: var(--color-background);
  border-radius: 1rem;
  border: 2px solid var(--color-background-selected-menubar);
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex: 1;
`;
