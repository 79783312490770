/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/scope */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FiChevronDown,
  FiChevronRight,
  FiChevronUp,
  FiEdit,
  FiTrash,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Select from '../Select';

import ExportFile from './components/ExportFile';
import Pagination from './components/Pagination';
import LoaderPage from '../LoaderPage';

import {
  Container,
  Title,
  TableComponent,
  TableHeader,
  THeader,
  TableBody,
  TableCell,
  PreTableContainer,
  LastUpdate,
  SelectWithLabel,
} from './styles';

export interface HeaderProps {
  title: string;
  value: string;
  sortable?: boolean;
}

interface OptionsSelectProps {
  label: string;
  value: string | number | boolean;
}

interface TableProps {
  title?: string;
  header: HeaderProps[];
  data: any[];
  isSelected?: boolean;
  path?: string;
  isDetails?: boolean;
  exportFile?: string[] | 'all';
  ModalDetails?: React.FC<any>;
  modalDetailsProps?: any;
  pagination?: boolean;
  maxItemsPerPage?: number;
  isEditable?: (editableData: any) => void;
  isDelectable?: (id: string) => void;
  isCheck?: (ids: string[]) => void;
}

const Table: React.FC<TableProps> = ({
  title,
  header,
  data,
  isSelected,
  path,
  isDetails,
  ModalDetails,
  exportFile,
  modalDetailsProps,
  pagination,
  maxItemsPerPage,
  isEditable,
  isDelectable,
  isCheck,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalDetail, setModalDetails] = useState();
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [sortDirection, setSortDirection] = useState<number>(-1);
  const [sortItem, setSortItem] = useState('');
  const [page, setPage] = useState<any[]>([]);
  const [sortSelect, setSortSelect] = useState<OptionsSelectProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [checkBox, setCheckBox] = useState<string[]>([]);
  const [allCheckBox, setAllCheckBox] = useState<boolean>(false);

  useEffect(() => {
    if (isCheck) {
      isCheck(checkBox);
    }
  }, [checkBox, isCheck]);

  useEffect(() => {
    setDataTable(data);
    setPage(
      maxItemsPerPage ? data.slice(0, maxItemsPerPage) : data.slice(0, 20),
    );
  }, [data, maxItemsPerPage]);

  useEffect(() => {
    const sel = [] as OptionsSelectProps[];
    header.forEach(head => {
      if (head.sortable === true) {
        sel.push({ label: head.title, value: head.value });
      }
    });
    setSortSelect(sel);
  }, [header]);

  function toggleModal(): void {
    setIsOpen(!isOpen);
  }
  function toggleModalDetail(detail: any): void {
    toggleModal();
    setModalDetails(detail);
  }
  const history = useHistory();

  const handleClick = useCallback(
    (row: any, finalPath: string | undefined) => {
      if (isSelected && finalPath) {
        const [getPath, code] = finalPath.split(':');
        history.push(`${history.location.pathname}/${getPath}${row[code]}`);
      }
    },
    [history, isSelected],
  );

  const handlePage = useCallback(
    (currentPage: number, totalItens: number) => {
      setPage(
        dataTable.slice(
          (currentPage - 1) * totalItens,
          currentPage * totalItens,
        ),
      );
    },
    [dataTable],
  );

  const toggleSort = useCallback(
    (item: string) => {
      setLoading(true);
      if (sortDirection === 1) {
        const direction = -1;
        setSortDirection(-1);
        setSortItem(item);
        const newData = handleSort(item, direction, dataTable);
        setDataTable(newData);
        setPage(
          maxItemsPerPage
            ? newData.slice(0, maxItemsPerPage)
            : newData.slice(0, 20),
        );
        setLoading(false);
      }
      if (sortDirection === -1) {
        const direction = 1;
        setSortDirection(1);
        setSortItem(item);
        const newData = handleSort(item, direction, dataTable);
        setDataTable(newData);
        setPage(
          maxItemsPerPage
            ? newData.slice(0, maxItemsPerPage)
            : newData.slice(0, 20),
        );
        setLoading(false);
      }
    },
    [dataTable, maxItemsPerPage, sortDirection],
  );

  function handleSort(item: string, direction: number, dataArray: any[]) {
    const sortData = dataArray.sort((a, b) => {
      if (a[item] > b[item]) {
        return direction;
      }
      if (a[item] < b[item]) {
        return -1 * direction;
      }
      return 0;
    });
    return sortData;
  }

  const handleSelectCheckBoxAll = useCallback(
    (value: boolean) => {
      if (value) {
        const allIds = data.map(item => item.id);
        setCheckBox(allIds);
        setAllCheckBox(true);
      } else {
        setCheckBox([]);
      }
    },
    [data],
  );

  const handleSelectCheckBox = useCallback(
    (id: string) => {
      if (checkBox.includes(id)) {
        const filteredCheckBox = checkBox.filter(item => item !== id);
        setCheckBox(filteredCheckBox);
      } else {
        const filteredCheckBox = [...checkBox, id];
        setCheckBox(filteredCheckBox);
      }
    },
    [checkBox],
  );

  return (
    <Container>
      <LoaderPage loading={loading} />
      <PreTableContainer>
        <LastUpdate />

        {exportFile && <ExportFile exportFile={exportFile} />}
      </PreTableContainer>
      <TableComponent>
        {title && <Title>{title}</Title>}

        {header.findIndex(head => head.sortable === true) >= 0 && (
          <SelectWithLabel>
            <span>Ordenar:</span>
            <Form onSubmit={() => {}} ref={formRef}>
              <Select
                placeholder="Tipo"
                name="sortSelect"
                options={sortSelect}
                onChange={value => toggleSort(value?.value)}
              />
            </Form>
          </SelectWithLabel>
        )}

        <TableHeader>
          <tr>
            {isCheck && (
              <TableCell data-label="Check">
                <input
                  type="checkbox"
                  onChange={e => handleSelectCheckBoxAll(e.target.checked)}
                  checked={checkBox.length === data.length}
                />
              </TableCell>
            )}
            {header.map(col => (
              <THeader
                key={col.value}
                onClick={col.sortable ? () => toggleSort(col.value) : () => {}}
                scope="col"
                sortable={col.sortable}
              >
                {col.title}
                {col.sortable &&
                  sortItem === col.value &&
                  (!(sortDirection + 1) ? <FiChevronUp /> : <FiChevronDown />)}
              </THeader>
            ))}
            {isEditable && (
              <THeader scope="col">
                <FiEdit />
              </THeader>
            )}
            {isDelectable && (
              <THeader scope="col">
                <FiTrash />
              </THeader>
            )}
            {isDetails && <THeader scope="col">Detalhes</THeader>}
            {isSelected && <THeader scope="col">Sel.</THeader>}
          </tr>
        </TableHeader>
        <TableBody isSelected={isSelected}>
          {page.map((row: any) => (
            <tr onClick={() => (path ? handleClick(row, path) : {})}>
              {isCheck && (
                <TableCell data-label="Deletar">
                  <input
                    type="checkbox"
                    onChange={e => handleSelectCheckBox(row.id)}
                    checked={checkBox.includes(row.id)}
                  />
                </TableCell>
              )}
              {header.map((item, index) => (
                <TableCell
                  key={item.value}
                  data-label={item.title}
                  index={index}
                >
                  {row[item.value]}
                </TableCell>
              ))}
              {isEditable && (
                <TableCell data-label="Editar" onClick={() => isEditable(row)}>
                  <div>
                    <FiEdit />
                  </div>
                </TableCell>
              )}
              {isDelectable && (
                <TableCell
                  data-label="Deletar"
                  onClick={() => isDelectable(row.id)}
                >
                  <FiTrash />
                </TableCell>
              )}
              {isDetails && (
                <TableCell
                  data-label="Detalhes"
                  onClick={() => toggleModalDetail(row)}
                >
                  <div>Detalhes</div>
                </TableCell>
              )}
              {isSelected && (
                <TableCell data-label="Sel.">
                  <FiChevronRight />
                </TableCell>
              )}
            </tr>
          ))}
        </TableBody>
      </TableComponent>
      {pagination && (
        <Pagination
          dataLength={data.length}
          maxItemsPerPage={maxItemsPerPage}
          setPage={handlePage}
        />
      )}
      {ModalDetails && (
        <ModalDetails
          isOpen={isOpen}
          setIsOpen={toggleModal}
          modalDetail={modalDetail}
          {...modalDetailsProps}
        />
      )}
    </Container>
  );
};

export default Table;
