/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useRef } from 'react';

import { FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  Container,
  Title,
  Close,
  FormContainer,
  SelectWithLabel,
  FormContainerSelect,
} from './styles';

import Modal from '../Modal';
import Input from '../Input';
import Button from '../Button';
import Table, { HeaderProps } from '../Table';
import Select from '../Select';
import DatePicker from '../DatePicker';

interface FilterProps {
  year: number;
  startData: Date;
  endData: Date;
  autor: string;
}

interface OptionSelect {
  label: string;
  value: string | number | boolean;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleFilter: (filter: FilterProps) => void;
}

const ModalFilter: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleFilter,
}) => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    (data: FilterProps) => {
      handleFilter(data);
      setIsOpen();
    },
    [handleFilter, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Filtrar</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <div>Ano:</div>
              <Input placeholder="Ano" name="year" />
            </FormContainer>
            <FormContainer>
              <div>Autor:</div>
              <Select placeholder="Autor" name="autor" options={[]} />
            </FormContainer>
            <FormContainer>
              <div>Início:</div>
              <DatePicker
                placeholderText="Data inicial"
                name="startDate"
                defaultDate={new Date('2021-01-01')}
              />
            </FormContainer>
            <FormContainer>
              <div>Final:</div>
              <DatePicker
                placeholderText="Data final"
                name="endDate"
                defaultDate={new Date()}
              />
            </FormContainer>
            <Button type="submit" buttonStyle="success">
              Filtrar
            </Button>
          </Form>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalFilter;
