import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  width: 100%;
  background: var(--color-background);
`;

export const GabineteContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  button.add {
    margin: auto 2rem;
    border-radius: 50%;
    background: var(--color-secondary);
    color: var(--color-text-in-secondary);
    width: 5rem;
    height: 5rem;
    border: 0;
    font-size: 2rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: 0.2s;
    &:hover {
      background: var(--color-secondary-shade);
    }
  }
`;

export const GabineteCard = styled.div`
  background: #fff;
  border-radius: 1.5rem;
  width: 26rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  transition: 0.2s;

  button {
    max-height: 4rem;
  }

  img {
    max-width: 18rem;
    border-radius: 1.5rem;
    margin: 1rem auto;
  }

  & + div {
    margin-left: 3rem;
  }

  &:hover {
    transform: scale(1.01);
  }
`;
export const HeaderGabineteCard = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;
export const FooterGabineteCard = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;

export const BodyGabinenteCard = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: 0;
`;
export const Title = styled.div`
  margin: 0 auto;
  color: var(--color-title-in-background);
  font-size: 2rem;
  font-size: 600;
`;
export const Cargo = styled.div`
  margin: 0.25rem auto;
  color: var(--color-text-in-background);
  font-size: 1.5rem;
  font-size: 500;
`;
export const Age = styled.div`
  width: 100%;
  margin: 0.25rem;
  color: var(--color-text-in-background);
  font-size: 1.5rem;
  font-size: 500;
`;
export const Describe = styled.div`
  width: 100%;
  margin: 0.25rem;
  color: var(--color-text-in-background);
  font-size: 1.5rem;
  font-size: 500;
  word-wrap: break-word;
  white-space: normal;
`;

export const Genero = styled.div`
  width: 100%;
  margin: 0.25rem;
  color: var(--color-text-in-background);
  font-size: 1.5rem;
  font-size: 500;
`;
