/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FiPlus, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Container, Title, Close, InputSection } from './styles';

import ModalLarge from '../ModalLarge';
import Input from '../Input';
import Button from '../Button';

interface CanaisProps {
  id: string;
  title: string;
  description: string;
  icon: string;
  url: string;
  priority: number;
  created_at: Date;
  updated_at: Date;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleEditCanal: (editCanal: CanaisProps) => void;
  editCanal: CanaisProps;
}

const ModalEditCanal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleEditCanal,
  editCanal,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: CanaisProps) => {
      handleEditCanal({
        ...editCanal,
        ...data,
      });
      setIsOpen();
    },
    [editCanal, handleEditCanal, setIsOpen],
  );

  return (
    <ModalLarge isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Canal</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form initialData={editCanal} ref={formRef} onSubmit={handleSubmit}>
            <InputSection>
              <Input placeholder="Título" name="title" />
              <Input placeholder="Descrição" name="description" />
              <div className="link">
                {'Copie o código do ícone no site '}
                <a
                  target="_blank"
                  href="https://oblador.github.io/react-native-vector-icons/"
                >
                  react-vector-icons
                </a>
                {' da seção Ionicons'}
              </div>
              <Input placeholder="Icone" name="icon" />
              <Input placeholder="Url" name="url" />
            </InputSection>
            <Button type="submit" buttonStyle="primary">
              Editar
            </Button>
            <Button type="button" buttonStyle="danger" onClick={setIsOpen}>
              Cancelar
            </Button>
          </Form>
        </section>
      </Container>
    </ModalLarge>
  );
};

export default ModalEditCanal;
