import styled from 'styled-components';

export const Container = styled.a`
  min-height: 4rem;
  max-height: 6rem;
  border-radius: 0.75rem;
  border: 0;
  padding: 0 1rem;
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  svg {
    margin: auto 0.5rem;
  }

  background: var(--color-primary);
  color: var(--color-button-text);
  &:hover {
    background: var(--color-primary-shade);
  }
`;
