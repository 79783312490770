import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const BackTo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  svg {
    margin-right: 0.25rem;
  }

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;
export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 1rem 0;
`;
export const Item = styled.div`
  padding: 0.5rem 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  font-size: 1.25rem;
  &::before {
    content: attr(data-label) ':';
    float: left;
    font-weight: bold;
    margin-right: 0.5rem;
  }
`;

export const OptionsMessage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0.25rem 0;
  column-gap: 1rem;
`;
export const Read = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  svg {
    margin-right: 0.25rem;
  }

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;
export const Delete = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  svg {
    margin-right: 0.25rem;
  }

  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;
