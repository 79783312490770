import styled from 'styled-components';

interface TableBodyProps {
  isSelected: boolean | undefined;
}

interface THeaderProps {
  sortable?: boolean;
}

interface TableCellProps {
  index?: number;
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

export const Title = styled.caption`
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--color-text);
  margin: 0.25rem 0;
  @media (min-width: 1100px) {
    font-size: 1.5rem;
  }
`;

export const PreTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
`;
export const LastUpdate = styled.div``;

export const TableComponent = styled.table`
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const TableHeader = styled.thead`
  tr {
    border-bottom: 0.15rem solid var(--color-background-menubar);
    @media (max-width: 1100px) {
      display: block;
    }
  }
  @media (max-width: 1100px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const THeader = styled.th<THeaderProps>`
  padding: 0.5rem 1rem;
  text-align: center;
  color: var(--color-background-menubar);
  text-transform: uppercase;
  text-decoration: ${props => (props.sortable ? 'underline' : 'none')};
  svg {
    margin-left: 0.25rem;
  }
  &:hover {
    cursor: ${props => (props.sortable ? 'pointer' : 'auto')};
  }
`;

export const TableBody = styled.tbody<TableBodyProps>`
  color: var(--color-background-menubar);
  tr {
    padding: 0.35rem;
    border-bottom: 0.05rem solid var(--color-background-menubar);
    &:hover {
      cursor: ${props => (props.isSelected ? 'pointer' : 'auto')};
    }
    & + tr:nth-child(2n) {
      background: #e3f2fd;
    }
    @media (max-width: 1100px) {
      margin-bottom: 0.25em;
      display: block;
    }
  }
`;

export const TableCell = styled.td<TableCellProps>`
  padding: 0.5rem 1rem;
  text-align: ${props => (props.index === 0 ? 'start' : 'center')};
  text-indent: ${props => (props.index === 0 ? '0 .5rem' : '0')};
  > div {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 1100px) {
    padding: 0.5rem 0.25rem;
    display: block;
    font-size: 1.25rem;
    text-align: right;
    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 0.5rem;
    }
  }
`;

export const SelectWithLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: center;
  form {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 1100px) {
    display: none;
  }
`;
