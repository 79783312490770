/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiArrowLeft, FiMail, FiTrash } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import EditorText from '../../components/EditorText';

import {
  Container,
  Content,
  BackTo,
  MessageContainer,
  Item,
  OptionsMessage,
  Read,
  Delete,
} from './styles';
import api from '../../services/api';
import ModalDelete from '../../components/ModalDelete';
import Button from '../../components/Button';
import 'draft-js/dist/Draft.css';

interface UserProps {
  id: string;
  name: string;
  email: string;
}

interface MessageProps {
  id: string;
  title: string;
  message: string;
  read: boolean;
  readFormatted: string;
  user: UserProps;
  user_id: string;
  userName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}
interface ParamsProps {
  id: string;
}
const SelectedMessage: React.FC = () => {
  const { id } = useParams<ParamsProps>();
  const [message, setMessage] = useState<MessageProps>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState<string>('');
  const [answer, setAnswer] = useState();

  const history = useHistory();

  useEffect(() => {
    async function loadMessage(message_id: string): Promise<void> {
      const response = await api.get(`/message/find/${message_id}`);

      if (!response.data) {
        history.push('/message');
      }

      const messageFormatted = response.data;

      await handleReadMessage(messageFormatted.id, true);

      messageFormatted.userName = messageFormatted.user.name;
      messageFormatted.readFormatted = 'Lido';
      messageFormatted.dateFormatted = format(
        new Date(messageFormatted.created_at),
        'dd/MM/yyyy',
      );

      setMessage(messageFormatted);
    }
    loadMessage(id);
  }, [history, id]);

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  function handleDeleteMessageModal(message_id: string): void {
    setDeleteMessage(message_id);
    toggleDeleteModal();
  }
  const handleBack = useCallback(() => {
    history.push('/message');
  }, [history]);

  async function handleDeleteMessage(message_id: string): Promise<void> {
    await api.delete(`/message/${message_id}`);
    history.push('/message');
  }

  async function handleReadMessage(
    message_id: string,
    read: boolean,
  ): Promise<void> {
    await api.put(`/message/read/${message_id}`, { read });
  }

  const handleAnswer = useCallback(async () => {
    await api.post(`/message/answer/${id}`, { answer });
    history.push('/message');
  }, [answer, history, id]);

  const handleUnRead = useCallback(async () => {
    await handleReadMessage(id, false);
    history.push('/message');
  }, [history, id]);
  return (
    <Container>
      <Menu />
      <Body title="Responder Mensagem">
        <Content>
          <OptionsMessage>
            <Read onClick={handleUnRead}>
              <FiMail />
              Marcar como não lido
            </Read>
            |
            <Delete onClick={() => handleDeleteMessageModal(id)}>
              <FiTrash />
              Deletar Mensagem
            </Delete>
            |
            <BackTo onClick={handleBack}>
              <FiArrowLeft />
              Voltar para mensagens
            </BackTo>
          </OptionsMessage>
          <MessageContainer>
            <Item data-label="Nome">{message?.userName}</Item>
            <Item data-label="E-mail">{message?.user.email}</Item>
            <Item data-label="Mensagem">{message?.message}</Item>
          </MessageContainer>
          <EditorText setContent={setAnswer} />
          <Button buttonStyle="primary" type="submit" onClick={handleAnswer}>
            Enviar Mensagem
          </Button>
        </Content>
      </Body>
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteMessage}
        deleteNewsId={deleteMessage}
      />
    </Container>
  );
};

export default SelectedMessage;
