import { IconBaseProps } from 'react-icons';

import {
  FiHome,
  FiMessageSquare,
  FiFileText,
  FiUsers,
  FiTv,
  FiUser,
  FiUserPlus,
  FiBell,
  FiPaperclip,
  FiAtSign,
} from 'react-icons/fi';

import Dashboard from '../pages/Dashboard';
import Gabinete from '../pages/Gabinete';
import Messages from '../pages/Messages';
import News from '../pages/News';
import Subjects from '../pages/Subjects';
import Perfil from '../pages/Perfil';
import ManagerAdmin from '../pages/ManagerAdmin';
import Notification from '../pages/Notification';
import Jornal from '../pages/Jornal';
import Canais from '../pages/Canais';
import Demand from '../pages/Demand';

interface MenuOptionsProps {
  title: string;
  path: string;
  component: React.FC;
}
interface MenuProps {
  title: string;
  path: string;
  icon: React.ComponentType<IconBaseProps>;
  component: React.FC;
  subMenu: MenuOptionsProps[];
}

export default {
  admin: [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: FiHome,
      component: Dashboard,
      subMenu: [],
    },
    {
      title: 'News',
      path: '/news',
      icon: FiTv,
      component: News,
      subMenu: [],
    },
    {
      title: 'Jornal',
      path: '/jornal',
      icon: FiPaperclip,
      component: Jornal,
      subMenu: [],
    },
    {
      title: 'Gabinete',
      path: '/gabinete',
      icon: FiUsers,
      component: Gabinete,
      subMenu: [],
    },
    {
      title: 'Mensagens',
      path: '/message',
      icon: FiMessageSquare,
      component: Messages,
      subMenu: [],
    },
    {
      title: 'Matérias',
      path: '/subject',
      icon: FiFileText,
      component: Subjects,
      subMenu: [],
    },
    {
      title: 'Outros Canais',
      path: '/canais',
      icon: FiAtSign,
      component: Canais,
      subMenu: [],
    },
    {
      title: 'Notificação',
      path: '/notification',
      icon: FiBell,
      component: Notification,
      subMenu: [],
    },
    {
      title: 'Demanda',
      path: '/demand',
      icon: FiMessageSquare,
      component: Demand,
      subMenu: [],
    },
    {
      title: 'Gerenciar Admins',
      path: '/admins',
      icon: FiUserPlus,
      component: ManagerAdmin,
      subMenu: [],
    },
    {
      title: 'Perfil',
      path: '/perfil',
      icon: FiUser,
      component: Perfil,
      subMenu: [],
    },
  ] as MenuProps[],
  editor: [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: FiHome,
      component: Dashboard,
      subMenu: [],
    },
    {
      title: 'News',
      path: '/news',
      icon: FiTv,
      component: News,
      subMenu: [],
    },
    {
      title: 'Jornal',
      path: '/jornal',
      icon: FiPaperclip,
      component: Jornal,
      subMenu: [],
    },
    {
      title: 'Gabinete',
      path: '/gabinete',
      icon: FiUsers,
      component: Gabinete,
      subMenu: [],
    },
    {
      title: 'Mensagens',
      path: '/message',
      icon: FiMessageSquare,
      component: Messages,
      subMenu: [],
    },
    {
      title: 'Matérias',
      path: '/subject',
      icon: FiFileText,
      component: Subjects,
      subMenu: [],
    },
    {
      title: 'Notificação',
      path: '/notification',
      icon: FiBell,
      component: Notification,
      subMenu: [],
    },
    {
      title: 'Demanda',
      path: '/demand',
      icon: FiMessageSquare,
      component: Demand,
      subMenu: [],
    },
    {
      title: 'Perfil',
      path: '/perfil',
      icon: FiUser,
      component: Perfil,
      subMenu: [],
    },
  ] as MenuProps[],
};
