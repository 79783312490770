import React, { useState } from 'react';
import { EditorState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Container, IPhoneNewsPreview, MainImage, Title, ActionContainer, TextAction, View } from './styles';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './App.css'
import {stateToHTML} from 'draft-js-export-html';
import IphoneFrame from '../../IphoneFrame';
import { IoMdHeart } from 'react-icons/io'
import example from '../../../assets/ex.jpg'


const App = ({ isPreview, setContent, photo, title }) => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const  [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
    setContent(convertedContent)
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = stateToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }
  const createMarkup = (html) => {
    return  {
      __html: DOMPurify.sanitize(html)
    }
  }
  return (
    <Container isPreview={isPreview}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        />
      {isPreview &&
          <IphoneFrame>
            <IPhoneNewsPreview>
                <MainImage src={photo || example} />
                <Title>{title}</Title>
                <ActionContainer>
                  <IoMdHeart size={18} />
                  <TextAction>Curtir</TextAction>
                </ActionContainer>
                <View>
                  <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)} />
                </View>
            </IPhoneNewsPreview>
          </IphoneFrame>
      }

    </Container>
  )
}
export default App;
