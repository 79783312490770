import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 0.75rem 0;
  margin-bottom: 2rem;

  form {
    display: flex;
    width: 70%;
    flex: 1;
    flex-direction: column;
    column-gap: 1rem;
    margin-right: 1rem;

    button {
      margin-top: 0;
      height: 100%;
    }
  }
`;

export const FormContainer = styled.div`
  display: grid;
  width: 100%;
  column-gap: 1rem;
  margin: 0.25rem 0;
  padding: 0.25rem 0;
  grid-template-columns: 1fr 1fr 1fr;

  > div + div {
    margin-top: 0;
  }
`;
