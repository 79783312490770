import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SelectedMessage from '../pages/SelectedMessage';

import globalOptions from '../config/global';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={SignIn} />

    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password" component={ResetPassword} />

    {globalOptions.admin.map(option => (
      <Route exact path={option.path} component={option.component} isPrivate />
    ))}
    {globalOptions.admin.map(option =>
      option.subMenu.map(submenu => (
        <Route
          exact
          path={submenu.path}
          component={submenu.component}
          isPrivate
        />
      )),
    )}

    <Route
      exact
      path="/message/answer/:id"
      component={SelectedMessage}
      isPrivate
    />
  </Switch>
);

export default Routes;
