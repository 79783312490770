/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useRef, useState } from 'react';

import { FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Container, Title, Close, FormContainer } from './styles';

import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import Select from '../Select';
import DatePicker from '../DatePicker';
import Table, { HeaderProps } from '../Table';

interface TypesProps {
  id: string;
  title: string;
  code: string;
  description: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleAddTypes: (newType: Omit<TypesProps, 'id'>) => void;
  handleEditTypes: (newType: TypesProps) => void;
  handleDeleteTypes: (id: string) => void;
  types: TypesProps[];
}

const ModalAddType: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleAddTypes,
  handleEditTypes,
  handleDeleteTypes,
  types,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editIdType, setEditIdType] = useState('');

  const headerTypes: HeaderProps[] = [
    {
      title: 'Sigla',
      value: 'code',
    },
    {
      title: 'Título',
      value: 'title',
    },
  ];

  const handleSubmit = useCallback(
    async (data: TypesProps) => {
      if (!isEdit) {
        handleAddTypes(data);
        formRef.current?.clearField('code');
        formRef.current?.clearField('title');
        formRef.current?.clearField('description');
        setIsEdit(false);
        setEditIdType('');
      } else {
        handleEditTypes({
          ...data,
          id: editIdType,
        });
        formRef.current?.clearField('code');
        formRef.current?.clearField('title');
        formRef.current?.clearField('description');
        setIsEdit(false);
        setEditIdType('');
      }
    },
    [isEdit, handleAddTypes, handleEditTypes, editIdType],
  );

  const handleEditType = useCallback(
    (data: any) => {
      formRef.current?.setFieldValue('code', data.code);
      formRef.current?.setFieldValue('title', data.title);
      formRef.current?.setFieldValue('description', data.description);
      setEditIdType(data.id);
      setIsEdit(true);
    },
    [formRef],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Matérias</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <Input placeholder="Sigla" name="code" />
              <Input placeholder="Título" name="title" />
            </FormContainer>
            <Input placeholder="Para que server?" name="description" />
            <Button type="submit" buttonStyle="success">
              {isEdit ? 'Editar' : 'Adicionar'}
            </Button>
          </Form>
          <Table
            data={types}
            header={headerTypes}
            isEditable={data => handleEditType(data)}
          />
        </section>
      </Container>
    </Modal>
  );
};

export default ModalAddType;
