import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiLock, FiMail, FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import getValidationErrors from '../../utils/getValidationError';

import { Container, HeaderSection, Content, FormContainer } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import ModalDelete from '../../components/ModalDelete';
import Select from '../../components/Select';
import { useToast } from '../../hooks/toast';
import capitalize from '../../utils/capitalize';

interface AdminProps {
  id: string;
  name: string;
  email: string;
  responsibility: string;
  responsibilityFormatted: string;
}

interface AdminData {
  id: string;
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  responsibility: string;
}

const ManagerAdmin: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { admin } = useAuth();
  const [admins, setAdmins] = useState<AdminProps[]>([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editNews, setEditNews] = useState<AdminProps>({} as AdminProps);
  const [deleteAdmins, setDeleteAdmins] = useState<string>('');

  const adminsHeader = [
    {
      title: 'Nome',
      value: 'name',
    },
    {
      title: 'E-mail',
      value: 'email',
    },
    {
      title: 'Cargo',
      value: 'responsibilityFormatted',
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadAdmins(): Promise<void> {
      const response = await api.get('/admins');

      const adminsFormatted = response.data.map((newAdmin: AdminProps) => {
        return {
          ...newAdmin,
          responsibilityFormatted: capitalize(newAdmin.responsibility),
        };
      });

      setAdmins(adminsFormatted);
    }
    loadAdmins();
  }, []);

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  const handleAddAdmin = useCallback(
    async (data: AdminData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'No mínimo 6 digitos'),
          password_confirmation: Yup.string()
            .min(6, 'No mínimo 6 digitos')
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const response = await api.post('/admins', data);

        setAdmins([...admins, response.data]);

        formRef.current?.reset();

        addToast({
          type: 'success',
          title: 'Admin adicionado!',
          description: 'Admin adicionado com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao criar Admin',
          description: 'Ocorreu um erro ao criar o admin.',
        });
      }
    },
    [addToast, admins],
  );

  function handleDeleteNewsModal(id: string): void {
    setDeleteAdmins(id);
    toggleDeleteModal();
  }

  async function handleDeleteNews(id: string): Promise<void> {
    await api.delete(`/admins/${id}`);
    const updateAdmins = admins.filter(deleteAdmin => deleteAdmin.id !== id);
    setAdmins([...updateAdmins]);
  }

  return (
    <Container>
      <Menu />
      <Body title="Gerenciar Admins">
        <Content>
          <HeaderSection>
            <Form ref={formRef} onSubmit={handleAddAdmin}>
              <FormContainer>
                <Input placeholder="Nome" name="name" icon={FiUser} />
                <Input placeholder="E-mail" name="email" icon={FiMail} />
                <Select
                  name="responsibility"
                  options={[
                    { label: 'Admin', value: 'admin' },
                    { label: 'Editor', value: 'editor' },
                  ]}
                  placeholder="Cargo"
                />
              </FormContainer>
              <FormContainer>
                <Input
                  type="password"
                  placeholder="Senha"
                  name="password"
                  icon={FiLock}
                />
                <Input
                  type="password"
                  placeholder="Confirmar senha"
                  name="password_confirmation"
                  icon={FiLock}
                />
                <Button buttonStyle="success" type="submit">
                  Adicionar
                </Button>
              </FormContainer>
            </Form>
          </HeaderSection>
          <Table data={admins} header={adminsHeader} />
        </Content>
      </Body>
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteNews}
        deleteNewsId={deleteAdmins}
      />
    </Container>
  );
};

export default ManagerAdmin;
