/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FiChevronDown, FiChevronUp, FiPlus, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import produce from 'immer';

import { Container, Title, Close, InputSection, Canal } from './styles';

import ModalLarge from '../ModalLarge';
import Input from '../Input';
import Button from '../Button';
import Modal from '../Modal';

interface CanaisProps {
  id: string;
  title: string;
  description: string;
  icon: string;
  url: string;
  priority: number;
  created_at: Date;
  updated_at: Date;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleEditCanais: (editCanal: CanaisProps[]) => void;
  canais: CanaisProps[];
}

const ModalEditPositionCanal: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleEditCanais,
  canais,
}) => {
  const [canaisTemp, setCanaisTemp] = useState<CanaisProps[]>([]);

  useEffect(() => {
    setCanaisTemp(canais);
  }, [canais]);

  const handleSubmit = useCallback(() => {
    handleEditCanais(canaisTemp);
    setIsOpen();
  }, [handleEditCanais, setIsOpen, canaisTemp]);

  const handleUpPosition = useCallback(
    (index: number) => {
      if (index !== 0) {
        const updateCanais = produce(canaisTemp, draft => {
          draft[index].priority = draft[index].priority + 1;
          draft[index - 1].priority = draft[index - 1].priority - 1;
          return draft;
        });
        setCanaisTemp(
          updateCanais.slice().sort((a, b) => {
            return b.priority - a.priority;
          }),
        );
      }
    },
    [canaisTemp],
  );

  const handleDownPosition = useCallback(
    (index: number) => {
      if (index !== canaisTemp.length - 1) {
        const updateCanais = produce(canaisTemp, draft => {
          draft[index].priority = draft[index].priority - 1;
          draft[index + 1].priority = draft[index + 1].priority + 1;
          return draft;
        });
        setCanaisTemp(
          updateCanais.slice().sort((a, b) => {
            return b.priority - a.priority;
          }),
        );
      }
    },
    [canaisTemp],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Canal</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <div>
            <InputSection>
              {canaisTemp.map((canal, index) => (
                <Canal key={canal.id}>
                  <div>{canal.title}</div>
                  <div>
                    <div className="up" onClick={() => handleUpPosition(index)}>
                      <FiChevronUp />
                    </div>
                    <div
                      className="down"
                      onClick={() => handleDownPosition(index)}
                    >
                      <FiChevronDown />
                    </div>
                  </div>
                </Canal>
              ))}
            </InputSection>
            <Button onClick={handleSubmit} type="submit" buttonStyle="primary">
              Editar
            </Button>
            <Button type="button" buttonStyle="danger" onClick={setIsOpen}>
              Cancelar
            </Button>
          </div>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalEditPositionCanal;
