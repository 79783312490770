import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiSearch } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import getValidationErrors from '../../utils/getValidationError';
import { Container, HeaderSection, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import ModalAddNews from '../../components/ModalAddNews';
import ModalEditNews from '../../components/ModalEditNews';
import { useAuth } from '../../hooks/auth';
import ModalDelete from '../../components/ModalDelete';

interface AdminProps {
  id: string;
  name: string;
}

interface SearchProps {
  search: string;
}

interface NewsProps {
  id: string;
  photo: string;
  title: string;
  description: string;
  admin: AdminProps;
  admin_id: string;
  adminName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}
const News: React.FC = () => {
  const { admin } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [allNews, setAllNews] = useState<NewsProps[]>([]);
  const [news, setNews] = useState<NewsProps[]>([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editNews, setEditNews] = useState<NewsProps>({} as NewsProps);
  const [deleteNews, setDeleteNews] = useState<string>('');

  const newsHeader = [
    {
      title: 'Título',
      value: 'title',
    },
    {
      title: 'Criado em',
      value: 'dateFormatted',
    },
    {
      title: 'Criado por',
      value: 'adminName',
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadNews(): Promise<void> {
      const response = await api.get('/news');

      const NewsFormatted = response.data.map((newsResponse: NewsProps) => {
        return {
          ...newsResponse,
          adminName: newsResponse.admin.name,
          dateFormatted: format(
            new Date(newsResponse.created_at),
            'dd/MM/yyyy',
          ),
        };
      });

      setAllNews(NewsFormatted);
      setNews(NewsFormatted);
    }
    loadNews();
  }, []);

  function toggleAddModal(): void {
    setAddModal(!addModal);
  }

  function toggleEditModal(): void {
    setEditModal(!editModal);
  }

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  const handleSubmit = useCallback(
    async (data: SearchProps) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          search: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const fuse = new Fuse(allNews, {
          keys: [
            {
              name: 'title',
              weight: 2,
            },
            {
              name: 'description',
              weight: 1,
            },
          ],
        });
        if (data.search === '') {
          setNews(allNews);
        } else {
          const res = fuse.search(data.search).map(result => {
            return result.item;
          });
          setNews(res);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [allNews],
  );

  function handleEditNews(updateNews: NewsProps): void {
    setEditNews(updateNews);
    toggleEditModal();
  }

  async function handleAddNews(
    newNews: Omit<
      NewsProps,
      | 'id'
      | 'adminName'
      | 'dateFormatted'
      | 'admin'
      | 'created_at'
      | 'updated_at'
    >,
  ): Promise<void> {
    try {
      const newsFormatted = newNews;

      newsFormatted.admin_id = admin.id;

      const response = await api.post('/news', newsFormatted);

      const newNewsFormatted = response.data;
      newNewsFormatted.adminName = admin.name;
      newNewsFormatted.dateFormatted = format(
        new Date(newNewsFormatted.created_at),
        'dd/MM/yyyy',
      );

      setNews([newNewsFormatted, ...news]);
      setAllNews([newNewsFormatted, ...news]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateNews(
    updatedNews: Omit<
      NewsProps,
      | 'id'
      | 'adminName'
      | 'dateFormatted'
      | 'admin'
      | 'created_at'
      | 'updated_at'
    >,
  ): Promise<void> {
    const newWithAdminId = updatedNews;
    newWithAdminId.admin_id = admin.id;
    await api.put(`/news/${editNews.id}`, newWithAdminId).then(response => {
      const newNews = response.data;

      const indexGabinete = news.findIndex(
        findNews => findNews.id === editNews.id,
      );

      newNews.adminName = newNews.admin.name;
      newNews.dateFormatted = format(
        new Date(newNews.created_at),
        'dd/MM/yyyy',
      );

      news[indexGabinete] = newNews;

      setNews([...news]);
      setAllNews([...news]);
    });
  }

  function handleDeleteNewsModal(id: string): void {
    setDeleteNews(id);
    toggleDeleteModal();
  }

  async function handleDeleteNews(id: string): Promise<void> {
    await api.delete(`/news/${id}`);
    const updateNews = news.filter(deleteNew => deleteNew.id !== id);
    setNews([...updateNews]);
    setAllNews([...updateNews]);
  }

  async function handlePhotoChange(
    picture: any,
    id: string,
  ): Promise<NewsProps> {
    const data = new FormData();

    data.append('avatar', picture);
    const response = await api.patch(`/news/photo/${id}`, data);
    return response.data;
  }

  return (
    <Container>
      <Menu />
      <Body title="News">
        <Content>
          <HeaderSection>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                placeholder="Pesquisar Título"
                name="search"
                icon={FiSearch}
              />
              <Button buttonStyle="primary" type="submit">
                Pesquisar
              </Button>
            </Form>
            <Button
              buttonStyle="success"
              type="button"
              onClick={toggleAddModal}
            >
              Adicionar News
            </Button>
          </HeaderSection>
          <Table
            data={news}
            header={newsHeader}
            pagination
            maxItemsPerPage={20}
            isEditable={handleEditNews}
            isDelectable={handleDeleteNewsModal}
          />
        </Content>
      </Body>
      <ModalAddNews
        isOpen={addModal}
        setIsOpen={toggleAddModal}
        handleAddNews={handleAddNews}
      />
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteNews}
        deleteNewsId={deleteNews}
      />
      <ModalEditNews
        isOpen={editModal}
        setIsOpen={toggleEditModal}
        handleEditNews={handleUpdateNews}
        editingNews={editNews}
      />
    </Container>
  );
};

export default News;
