import { type } from 'os';
import React, { useEffect, useState } from 'react';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { IconBaseProps } from 'react-icons';
import {
  GrDocumentCsv,
  GrDocumentExcel,
  GrDocumentPdf,
  GrDocumentRtf,
  GrDocumentTxt,
  GrDocumentWord,
  GrPrint,
} from 'react-icons/gr';
import { Container, RetractButton, Content, TypeContainer } from './styles';

interface ExportFileProps {
  exportFile: string[] | 'all';
}

interface TypeProps {
  type: string;
  name: string;
  Icon: React.ComponentType<IconBaseProps>;
}

const ExportFile: React.FC<ExportFileProps> = ({ exportFile }) => {
  const [allTypeFile, setAllTypeFile] = useState<TypeProps[]>([
    {
      type: 'print',
      name: 'Imprimir',
      Icon: GrPrint,
    },
    {
      type: 'pdf',
      name: 'PDF',
      Icon: GrDocumentPdf,
    },
    {
      type: 'xls',
      name: 'XLS',
      Icon: GrDocumentExcel,
    },
    {
      type: 'txt',
      name: 'TXT',
      Icon: GrDocumentTxt,
    },
    {
      type: 'csv',
      name: 'CSV',
      Icon: GrDocumentCsv,
    },
    {
      type: 'rtf',
      name: 'RTF',
      Icon: GrDocumentRtf,
    },
    {
      type: 'doc',
      name: 'DOC',
      Icon: GrDocumentWord,
    },
  ]);
  const [typeFiles, setTypeFiles] = useState<TypeProps[]>([]);

  useEffect(() => {
    if (exportFile === 'all') {
      setTypeFiles(allTypeFile);
    } else {
      const selectTypeFiles = allTypeFile.filter(types =>
        exportFile.includes(types.type),
      );
      setTypeFiles(selectTypeFiles);
    }
  }, [allTypeFile, exportFile]);

  return (
    <Container>
      <Content>
        {typeFiles.map(types => (
          <TypeContainer key={types.type}>
            <types.Icon />
            <p>{types.name}</p>
          </TypeContainer>
        ))}
      </Content>
    </Container>
  );
};

export default ExportFile;
