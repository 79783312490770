import React, { memo, useMemo } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import header from '../../assets/pdfHeader.jpeg';
import ods1 from '../../assets/ods/1.png';
import ods2 from '../../assets/ods/2.png';
import ods3 from '../../assets/ods/3.png';
import ods4 from '../../assets/ods/4.png';
import ods5 from '../../assets/ods/5.png';
import ods6 from '../../assets/ods/6.png';
import ods7 from '../../assets/ods/7.png';
import ods8 from '../../assets/ods/8.png';
import ods9 from '../../assets/ods/9.png';
import ods10 from '../../assets/ods/10.png';
import ods11 from '../../assets/ods/11.png';
import ods12 from '../../assets/ods/12.png';
import ods13 from '../../assets/ods/13.png';
import ods14 from '../../assets/ods/14.png';
import ods15 from '../../assets/ods/15.png';
import ods16 from '../../assets/ods/16.png';
import ods17 from '../../assets/ods/17.png';
import { SubjectProps } from '.';

const odsType = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
];

type odsImage = {
  [key: string]: string;
};

const odsImages: odsImage = {
  '1': ods1,
  '2': ods2,
  '3': ods3,
  '4': ods4,
  '5': ods5,
  '6': ods6,
  '7': ods7,
  '8': ods8,
  '9': ods9,
  '10': ods10,
  '11': ods11,
  '12': ods12,
  '13': ods13,
  '14': ods14,
  '15': ods15,
  '16': ods16,
  '17': ods17,
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    paddingVertical: 40,
  },
  title: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 120,
    marginTop: 120,
  },
  h1: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  header: {
    position: 'absolute',
    width: '100%',
    flexShrink: 0,
    margin: 0,
    padding: 0,
    top: 0,
  },
  container: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
    flexShrink: 1,
  },
  containerHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexShrink: 1,
  },
  headerDetails: {
    width: '60%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  headerTitle: {
    fontSize: 24,
  },
  headerText: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 8,
    flexWrap: 'wrap',
    flexShrink: 1,
  },
  textBold: {
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 4,
  },
  textRegular: {
    fontSize: 12,
    fontWeight: 400,
  },
  ods: {
    width: '40%',
    height: 90,
    paddingBottom: 8,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  content: {
    width: '100%',
    paddingVertical: 8,
    flexShrink: 1,
  },
  odsImage: {
    maxHeight: '90%',
  },
  view: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 32,
  },
});

interface ResumaoPDFProps {
  title: string;
  data: SubjectProps[];
}

interface SubjectComponentProps {
  subject: SubjectProps;
}

const Subject = ({ subject }: SubjectComponentProps) => {
  return (
    <View key={subject.id} style={styles.container} wrap={false}>
      <View style={styles.containerHeader}>
        <View style={styles.headerDetails}>
          <Text style={styles.headerTitle}>
            {`${subject.type.title} N.${subject.numberFormatted}`}
          </Text>
          <View style={styles.headerText}>
            <Text style={styles.textBold}>Quando?</Text>
            <Text style={styles.textRegular}>
              {subject.initialDateFormatted}
            </Text>
          </View>
          <View style={styles.headerText}>
            <Text style={styles.textBold}>O que é?</Text>
            <Text style={styles.textRegular}>{subject.type.title}</Text>
          </View>
          <View style={styles.headerText}>
            <Text style={styles.textBold}>Pra que serve?</Text>
            <Text style={styles.textRegular}>{subject.type.description}</Text>
          </View>
        </View>
        <View style={styles.ods}>
          {subject.odsFormatted.length > 0 &&
            subject.odsFormatted.map(
              odsCode =>
                odsType.includes(odsCode) && (
                  <Image style={styles.odsImage} src={odsImages[odsCode]} />
                ),
            )}
        </View>
      </View>
      <View style={styles.content}>
        <Text style={styles.textRegular}>{subject.summary}</Text>
      </View>
    </View>
  );
};

const Resumao = ({ title, data }: ResumaoPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.header} src={header} />
        <View style={styles.title}>
          <Text style={styles.h1}>{title}</Text>
        </View>
        <View style={styles.view}>
          {data && data.map(sub => <Subject subject={sub} />)}
        </View>
      </Page>
    </Document>
  );
};

export const ResumaoPDF = memo(Resumao);
