import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.75rem 0;
  row-gap: 1rem;
  margin-bottom: 2rem;

  form {
    display: flex;
    width: 100%;
    height: 4.3rem;
    flex-direction: row;
    column-gap: 1rem;
    margin-right: 1rem;

    > button {
      margin-top: 0;
      max-width: 20%;
    }
  }
`;

export const AddContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;

  > button {
    margin-top: 0;
  }
`;
