/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FiCamera, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';
import { formatISO, parseISO } from 'date-fns';
import getValidationErrors from '../../utils/getValidationError';
import defaultUser from '../../assets/default-user-image.png';
import {
  Container,
  Title,
  Close,
  FirstSection,
  SecondSection,
  InputSection,
  AvatarInput,
} from './styles';

import Modal from '../Modal';
import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';
import DatePicker from '../DatePicker';
import Button from '../Button';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';

interface GabineteProps {
  id: string;
  name: string;
  email: string;
  photo: string;
  responsibility: string;
  description: string;
  dateOfBirth: Date;
  gender: string;
  age: number;
  responsibilityFormatted: string;
  genderFormatted: 'Feminino' | 'Masculino' | 'Neutro';
}

interface GabineteData {
  id: string;
  name: string;
  email: string;
  photo: string;
  responsibility: string;
  description: string;
  dateOfBirth: Date;
  date: string;
  gender: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleUpdateGabinete: (gabinete: GabineteData) => void;
  handleUpdateAvatarGabinete: (picture: any) => Promise<string>;
  editingGabinete: GabineteProps;
}

const ModalEditGabinete: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleUpdateGabinete,
  handleUpdateAvatarGabinete,
  editingGabinete,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [editGabinete, setEditGabinete] = useState(editingGabinete);

  useEffect(() => {
    setEditGabinete(editingGabinete);
  }, [editingGabinete]);

  const responsabilitySelect = [
    { label: 'Vereadora', value: 'vereador' },
    { label: 'Assessora', value: 'assessor' },
  ];

  const genderSelect = [
    { label: 'Feminino', value: 'f' },
    { label: 'Masculino', value: 'm' },
    { label: 'Neutro', value: 'n' },
  ];

  const handleSubmit = useCallback(
    async (data: GabineteData) => {
      const newData = data;
      newData.date = formatISO(newData.dateOfBirth);
      handleUpdateGabinete({
        ...editGabinete,
        ...newData,
      });
      setIsOpen();
    },
    [editGabinete, handleUpdateGabinete, setIsOpen],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];

        handleUpdateAvatarGabinete(file).then(response => {
          if (response) {
            setEditGabinete({
              ...editGabinete,
              photo: response,
            });
          }
        });
      }
    },
    [editGabinete, handleUpdateAvatarGabinete],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Editar</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={editGabinete}
          >
            <FirstSection>
              <AvatarInput>
                <img src={editGabinete.photo} alt="foto" />
                <label htmlFor="photo">
                  <FiCamera />
                  <input type="file" id="photo" onChange={handleAvatarChange} />
                </label>
              </AvatarInput>
              <InputSection>
                <Input placeholder="Nome" name="name" />
                <Input placeholder="E-mail" name="email" />
              </InputSection>
            </FirstSection>
            <SecondSection>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                name="dateOfBirth"
                placeholderText="Data de Nascimento"
                defaultDate={editGabinete.dateOfBirth}
                showYearDropdown
                showMonthDropdown
              />
              <Select
                options={responsabilitySelect}
                placeholder="Cargo"
                name="responsibility"
                defaultValue={{
                  value: editGabinete.responsibility,
                  label: editGabinete.responsibilityFormatted,
                }}
              />
              <Select
                options={genderSelect}
                placeholder="Gênero"
                name="gender"
                defaultValue={{
                  value: editGabinete.gender,
                  label: editGabinete.genderFormatted,
                }}
              />
            </SecondSection>
            <TextArea placeholder="Descrição" name="description" />
            <Button type="submit" buttonStyle="primary">
              Editar
            </Button>
          </Form>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalEditGabinete;
