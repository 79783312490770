/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FiFile, FiSearch } from 'react-icons/fi';
import { Form } from '@unform/web';
import { format, isAfter, isBefore } from 'date-fns';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Fuse from 'fuse.js';
import { parseISO } from 'date-fns/esm';
import Menu from '../../components/Menu';
import Body from '../../components/Body';
import getValidationErrors from '../../utils/getValidationError';
import { Container, HeaderSection, Content } from './styles';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Table, { HeaderProps } from '../../components/Table';
import api from '../../services/api';
import ModalAddNews from '../../components/ModalAddNews';
import { useAuth } from '../../hooks/auth';
import ModalDelete from '../../components/ModalDelete';
import ModalAddJornal from '../../components/ModalAddJornal';
import ModalEditJornal from '../../components/ModalEditJornal';
import ModalJornalPDF from '../../components/ModalJornalPDF';
import ModalAddCanal from '../../components/ModalAddCanal';
import ModalEditCanal from '../../components/ModalEditCanal';
import ModalEditPositionCanal from '../../components/ModalEditPositionCanal';

interface SearchProps {
  search: string;
}

export interface CanaisProps {
  id: string;
  title: string;
  description: string;
  icon: string;
  url: string;
  priority: number;
  created_at: Date;
  updated_at: Date;
}
const Canais: React.FC = () => {
  const [canais, setCanais] = useState<CanaisProps[]>([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editPositionModal, setEditPositionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editCanais, setEditCanais] = useState<CanaisProps>({} as CanaisProps);
  const [deleteCanais, setDeleteCanais] = useState<string>('');

  const canaisHeader = [
    {
      title: 'Título',
      value: 'title',
    },
  ] as HeaderProps[];

  useEffect(() => {
    async function loadCanais(): Promise<void> {
      const response = await api.get('/canais');

      setCanais(response.data);
    }
    loadCanais();
  }, []);

  function toggleAddModal(): void {
    setAddModal(!addModal);
  }

  function toggleEditPositionModal(): void {
    setEditPositionModal(!editPositionModal);
  }

  function toggleEditModal(): void {
    setEditModal(!editModal);
  }

  function toggleDeleteModal(): void {
    setDeleteModal(!deleteModal);
  }

  function handleEditCanal(updateCanal: CanaisProps): void {
    setEditCanais(updateCanal);
    toggleEditModal();
  }

  async function handleAddCanal(
    newCanal: Omit<CanaisProps, 'id' | 'created_at' | 'updated_at'>,
  ): Promise<void> {
    try {
      const response = await api.post('/canais', newCanal);

      setCanais([...canais, response.data]);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateCanal(updatedCanal: CanaisProps): Promise<void> {
    await api.put(`/canais/${editCanais.id}`, updatedCanal).then(response => {
      const newJornal = response.data;
      let updatedCanais: CanaisProps[] = canais.slice();

      const indexCanal = canais.findIndex(
        findCanal => findCanal.id === editCanais.id,
      );

      updatedCanais[indexCanal] = newJornal;

      setCanais(updatedCanais);
    });
  }

  async function handleUpdateCanais(
    updatedCanais: CanaisProps[],
  ): Promise<void> {
    await api.put(`/canais/all`, { canais: updatedCanais }).then(response => {
      setCanais(response.data);
    });
  }

  function handleDeleteCanalModal(id: string): void {
    setDeleteCanais(id);
    toggleDeleteModal();
  }

  async function handleDeleteCanal(id: string): Promise<void> {
    await api.delete(`/canais/${id}`);
    const updateCanais = canais.filter(item => item.id !== id);
    setCanais([...updateCanais]);
  }

  return (
    <Container>
      <Menu />
      <Body title="Canais">
        <Content>
          <HeaderSection>
            <Button
              buttonStyle="success"
              type="button"
              onClick={toggleAddModal}
            >
              Adicionar Canal
            </Button>
            <Button
              buttonStyle="primary"
              type="button"
              onClick={toggleEditPositionModal}
            >
              Editar posição
            </Button>
          </HeaderSection>
          <Table
            data={canais}
            header={canaisHeader}
            isEditable={handleEditCanal}
            isDelectable={handleDeleteCanalModal}
          />
        </Content>
      </Body>
      <ModalDelete
        isOpen={deleteModal}
        setIsOpen={toggleDeleteModal}
        title="Deletar"
        text="Você realmente quer deletar esse item?"
        handleDelete={handleDeleteCanal}
        deleteNewsId={deleteCanais}
      />
      <ModalAddCanal
        isOpen={addModal}
        handleAddCanal={handleAddCanal}
        priority={canais.length + 1}
        setIsOpen={toggleAddModal}
      />
      <ModalEditCanal
        isOpen={editModal}
        handleEditCanal={handleUpdateCanal}
        setIsOpen={toggleEditModal}
        editCanal={editCanais}
      />
      <ModalEditPositionCanal
        isOpen={editPositionModal}
        handleEditCanais={handleUpdateCanais}
        setIsOpen={toggleEditPositionModal}
        canais={canais}
      />
    </Container>
  );
};

export default Canais;
