import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  min-height: 500px;
  justify-content: flex-start;
  color: var(--color-text);
  font-size: 1.75rem;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  section {
    margin: 1rem 0 0.5rem 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    row-gap: 1rem;
    max-height: 600px;
    > form {
      display: flex;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
      row-gap: 1rem;
    }
  }
  a {
    text-decoration: none;
    color: var(--color-text);
  }
  @media (min-width: 1100px) {
    width: 768px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 3.5rem;
  img {
    margin-right: 0.5rem;
  }
`;
export const Close = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Item = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: 0.75rem 0;
  svg {
    min-width: 1.75rem;
    margin-right: 0.5rem;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  width: 100%;
  column-gap: 1rem;
  height: 5.5rem;
  grid-template-columns: 2fr 5fr;

  > div + div {
    margin-top: 0;
  }

  > button {
    margin: 0;
    height: 100%;
  }
`;
