/* eslint-disable import/extensions */
import React, { useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Container } from './styles';
import DraftComponent from './DraftComponent';

interface EditorTextProps {
  setContent: any;
  hasPreview?: boolean;
  photo?: any;
  title?: string;
}

const EditorText: React.FC<EditorTextProps> = ({
  hasPreview,
  setContent,
  photo,
  title,
}) => {
  return (
    <Container>
      <DraftComponent
        setContent={setContent}
        isPreview={hasPreview}
        photo={photo}
        title={title}
      />
    </Container>
  );
};

export default EditorText;
