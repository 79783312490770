/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FiPlus, FiX } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { SyncLoader } from 'react-spinners';
import { Container, Title, Close, InputSection, Image } from './styles';

import Modal from '../Modal';
import Input from '../Input';
import TextArea from '../TextArea';
import Button from '../Button';
import { PhotoInput } from '../ModalAddNews/styles';
import { uploadPhoto } from '../../utils/uploadPhoto';

interface AdminProps {
  id: string;
  name: string;
}

interface NewsProps {
  id: string;
  photo: string;
  title: string;
  description: string;
  admin: AdminProps;
  admin_id: string;
  adminName: string;
  dateFormatted: string;
  created_at: Date;
  updated_at: Date;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleEditNews: (
    newNews: Omit<
      NewsProps,
      | 'id'
      | 'adminName'
      | 'dateFormatted'
      | 'admin'
      | 'created_at'
      | 'updated_at'
    >,
  ) => void;
  editingNews: NewsProps;
}

const ModalEditNews: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleEditNews,
  editingNews,
}) => {
  const [filePicture, setFilePicture] = useState<any | null>(editingNews.photo);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFilePicture(editingNews.photo);
  }, [editingNews]);

  const handleSubmit = useCallback(
    async (data: NewsProps) => {
      handleEditNews({ ...data, photo: filePicture });
      setIsOpen();
    },
    [filePicture, handleEditNews, setIsOpen],
  );

  const handlePhoto = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLoading(true);
      uploadPhoto(e.target.files[0]).then(response => {
        setLoading(false);
        setFilePicture(response);
      });
    }
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <header>
          <Title>Adicionar</Title>
          <Close onClick={setIsOpen}>
            <FiX />
          </Close>
        </header>
        <section>
          <Form initialData={editingNews} ref={formRef} onSubmit={handleSubmit}>
            <InputSection>
              <Input placeholder="Título" name="title" />
              <Input
                placeholder="URL da noticia"
                name="description"
                type="url"
              />
              <PhotoInput>
                {loading ? (
                  <div className="loading-img">
                    <SyncLoader color="#0F4780" loading />
                  </div>
                ) : (
                  <Image src={filePicture} alt="imagem" />
                )}
                <label htmlFor="photo">
                  <FiPlus />
                  <input type="file" id="photo" onChange={handlePhoto} />
                </label>
              </PhotoInput>
            </InputSection>
            <Button type="submit" buttonStyle="primary">
              Editar
            </Button>
            <Button type="button" buttonStyle="danger" onClick={setIsOpen}>
              Cancelar
            </Button>
          </Form>
        </section>
      </Container>
    </Modal>
  );
};

export default ModalEditNews;
