import React, { memo, useMemo } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { format, parseISO } from 'date-fns';
import header from '../../assets/pdfHeader.jpeg';
import { JornalProps } from '.';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    paddingVertical: 40,
  },
  title: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 120,
    marginTop: 120,
  },
  h1: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  header: {
    position: 'absolute',
    width: '100%',
    flexShrink: 0,
    margin: 0,
    padding: 0,
    top: 0,
  },
  container: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
    flexShrink: 1,
  },
  containerHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexShrink: 1,
  },
  headerDetails: {
    width: '60%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  headerTitle: {
    fontSize: 24,
  },
  headerText: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 8,
    flexWrap: 'wrap',
    flexShrink: 1,
  },
  textBold: {
    fontSize: 13,
    fontStyle: 'bold',
    fontWeight: 'bold',
    marginRight: 4,
    marginBottom: 8,
  },
  textRegular: {
    fontSize: 12,
    fontWeight: 400,
  },
  ods: {
    width: '40%',
    height: 90,
    paddingBottom: 8,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 8,
    flexShrink: 1,
  },
  textContainer: {
    width: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexShrink: 1,
  },
  image: {
    maxWidth: 220,
    height: 180,
    marginHorizontal: 8,
  },
  view: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 32,
  },
});

interface JornalPDFProps {
  title: string;
  data: JornalProps[];
}

interface JornalComponentProps {
  jornal: JornalProps;
}

const Jornal = ({ jornal }: JornalComponentProps) => {
  return (
    <View key={jornal.title} style={styles.container} wrap={false}>
      <View style={styles.content}>
        <Image
          style={styles.image}
          src={{
            uri: jornal.photo,
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
            body: '',
          }}
        />
        <View style={styles.textContainer}>
          <Text style={styles.textBold}>
            {`${format(parseISO(jornal.date), 'dd/MM')} - ${jornal.title}`}
          </Text>
          <Text style={styles.textRegular}>{jornal.description}</Text>
        </View>
      </View>
    </View>
  );
};

const Resumao = ({ title, data }: JornalPDFProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.header} src={header} />
        <View style={styles.title}>
          <Text style={styles.h1}>{title}</Text>
        </View>
        <View style={styles.view}>
          {data && data.map(j => <Jornal jornal={j} />)}
        </View>
      </Page>
    </Document>
  );
};

export const ResumaoPDF = memo(Resumao);
